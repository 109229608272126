body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color : #f6f8fa */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainappBarImportant {
  z-index: 1100 !important;
}

.Toastify__toast--success{
  background : #4caf50 !important
}

.Toastify__toast-container{
  width : 410px !important
}

.pargraphMarginZero{
	margin : 0px !important;
}

.alignCenter{
	display: flex;
	justify-content: center;
}

.alignRight{
	display: flex;
	justify-content: flex-end;
}

.greyTitle{
  font-size: 0.8rem;
  color : #555;
  font-weight: 500;
}

.lightGreyTitle{
  font-size: 0.8rem;
  color : lightgray;
  font-weight: 500;
}

.displayFlexjustifySpacebetween{
  display: flex;
  justify-content: space-between;
}

.displayFlex{
  display: flex;
}

.oneRemLeftMarginSeperator{
  margin-left: 1rem !important;
}

.twoRemMarginTopAndoneRemBottomSeperator{
  margin-top:2rem !important;
  margin-bottom: 1rem !important;
}

.oneRemMarginTopAndoneRemBottomSeperator{
  margin-top:1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.italicfont{
  font-style: italic;
}

.padding1Rem{
  padding: 1rem !important;
}

.MuiStepIcon-root.MuiStepIcon-active{
  color : white !important;
  border : 1px solid #0057fa;
  border-radius: 50%;
}

.MuiStepIcon-text{
  fill: #0057fa !important;
}

.MuiStepLabel-label.MuiStepLabel-active{
  color : #0057fa !important;
}

.MuiStepIcon-root.MuiStepIcon-completed{
  color : #0057fa !important;
}

.card-Selected{
  background-color : #0057fa !important;
  color : white !important;
  padding : 20px !important;
  border-radius: 16px !important;
  -webkit-text-fill-color: white !important;
}

.MuiStepIcon-root{
  color : white !important;
  border : 1px solid #B7B9CB;
  border-radius: 50%;
}

.MuiSelect-icon{
  color: grey !important;
}

.twoRemMarginBottomSeperator{
margin-bottom: 2rem !important;
}

.oneRemMarginBottomSeperator{
margin-bottom: 1rem !important;
}

.textDecorationNone{
  text-decoration: none;
}
.uploadimgIcon{
  object-fit: cover;
}
.errorTxt{
  color:#e53935;
  font-size:11px;
}
.m-t-5{
  margin-top:5px!important;
}
.m-t-10{
  margin-top:10px!important;
}
.m-t-12{
  margin-top:12px!important;
}
.m-t-16{
  margin-top:16px!important;
}
.m-t-20{
  margin-top:20px!important;
}
.m-t-24{
  margin-top:24px!important;
}
.m-t-40{
  margin-top:40px!important;
}
.m-b-20{
  margin-bottom:20px!important;
}
.m-b-10{
  margin-bottom: 10px !important;
}
.m-b-5{
  margin-bottom:5px!important;
}
.m-10{
  margin:10px;
}
.m-20{
  margin:20px;
}
.m-r-10{
    margin-right: 10px!important;;
}
.m-r-20{
  margin-right:20px!important;
}
.m-l-5{
  margin-left: 5px !important;
}
.m-l-10{
  margin-left:10px!important;
}
.m-l-20 {
  margin-left:20px!important;
}
.m-l-40{
  margin-left:40px!important;
}
.mt-6{
  margin-top: 6px !important;
}
.mt-24{
  margin-top: 24px !important;
}
.mt-55{
  margin-top: 55px!important;
}
.mt-40{
  margin-top: 40px!important;
}
.p-b-10{
  padding-bottom: 10px !important;
}
/* .MuiPopover-paper {
  min-width:120px!important;
} */
/* .MuiDrawer-modal{
  z-index: 1302!important;
} */
/* .MuiDialog-root{
  z-index: 1302!important;
} */

.textend {
  text-align: end;
}

.notFoundGrid{
  margin-top: 20vh !important;
}
.w-100{
  width:100%;
}

.right{
  float:right;
}
.dialogtitle{
  /* color:#444;
  font-size:18px!important; */
  display: inline;
}

.margintoponerem{
  margin-top: 56px;
}

.gitIcon{
  font-size:16px;
  color:black;
  margin-right:10px;
}

.oneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.threeLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.fourLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.txtWidthWrap{
width: 80%;
overflow: hidden;
text-overflow: ellipsis;
}
.disctxt {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
  color:black!important;
  padding-bottom:20px;
}

.avoid-clicks {
  pointer-events: none;
}
.noCursor {
  cursor: not-allowed;
}

.Cache-block {
  position: absolute;
  z-index: 1400;
  left: 45%;
}

.listContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom:20px;
  align-items: center;
}

.activestatus{
  margin-right: 8px;
  background-color: #43a047;
  width: 10px;
  height: 10px;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  }
  
  .inactivestatus
  {
  margin-right: 8px;
  background-color: #e53935;
  width: 10px;
  height: 10px;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  }
 .destroyed{
    background:#eeeeee!important;
}

.clusterInfoStatus span{
  margin-left: 0 !important;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.installBtn{
  margin-right:20px!important;
  margin-top:20px!important;
}

.groupnameLink{
  cursor: pointer;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  /* content: ''; */
  display: block;
  border: 5px solid #0e42a3;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 250px;
  padding: 2px 0;
  background-color: #ec914e;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-align: center;
  font-size:15px;
  text-transform: none;
}

.ribbon-top-right {
  top: 0px;
  right: 0px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 38px;
}
.ribbon-top-right::after {
  bottom: 38px;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.rolloverenvinfo{
  font-weight: bold;
  font-style: italic;
  margin-left: 5px;
}
.rollenvtext{
  margin-left: 5px;; 
}

.rollbackpopup .MuiDialog-paperWidthSm{
  width: 600px;
}
.packagelistdiv{
/* height: 250px; */
overflow-y: auto;
overflow-x:hidden
}
.subscriptionPopup .MuiDialog-paperWidthSm{
  width: 600px;
}

.transform-180{
  transform: rotate(180deg)
}

.link-pointer {
  cursor: pointer; 
}

.infoGrid{
  display: "flex";
  align-items: "center";
}

.bggrey{
  background: #f8f8f8;
}

.centered {
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.svgicon{
  font-size: 14px!important;
  color: #919EAB;
  margin-right: 5px;
}

.operator-status{
  font-weight: 600;
  text-transform: capitalize;
}

.operator-status.installed{
  color: green;
}

.operator-status.failed{
  color: red;
}

.d-none{
  display: none;
}

.cluster-info-message {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.cluster-info-message .message {
  font-size: 14px !important;
  color: #43425D !important;
  line-height: 18px !important;
}

input[type=number] {
  -moz-appearance: textfield;
}

.whiteSpacePre{
  white-space: pre-wrap;
}

.execCommand{
  background-color: lightgray;
  padding-left: 10px;
  overflow-y:auto;
}

/* Carbon chart start */
.bx--content-switcher {
  margin-block: 1rem;
}

.bx--cc--card-node{
  height: max-content !important;
  border-radius: 10px;
}

.bx--cc--card-node.card-status-Succeeded {
  border-color: #198038;
}

/* .bx--cc--card-node.card-status-success-warning {
  border-color: #f1c21b;
} */

.bx--cc--card-node.card-status-Running {
  border-color: #f5c60b;
}

.bx--cc--card-node.card-status-Error,
.bx--cc--card-node.card-status-Failed {
  border-color: #da1e28;
}

.bx--cc--card-node.card-status-Pending {
  border-color: #8d8d8d;
}

.bx--cc--card-node__column {
  overflow: hidden;
}

.bx--cc--card-node__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bx--cc--shape-node__icon .bx--tooltip__label .bx--tooltip__trigger {
  margin-left: 0;
}

/* .bx--cc--shape-node__icon & {
    width: 24px;
    height: 24px;
} */
/* .status-icon.status-icon-failed {
  fill: #da1e28;
}

.status-icon.status-icon-Succeeded {
  fill: #198038;
}

.status-icon.status-icon-success-warning {
  fill: #198038;
}

.status-icon.status-icon-success-warning path:nth-child(2) {
  fill: #f1c21b;
} */

.tkn--pipeline-graph {
  margin-block: 1rem;
}

.tkn--pipeline-graph>svg {
  position: relative;
  margin-top: 1rem;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Carbon chart end */

.r-69 {
  border-radius: 69px !important;
}

/*---ProfileTab Start---*/

.delete {
  margin-top: 16px !important;
  text-transform: 'none'
}

.detailsContainer { 
  width: 100%;
}

.userDetaisContainer{
margin-left: 0.5rem;
flex: 1;
}

.userName{
color: #555555;
font-weight: 600;
margin:4px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 150px;
}

.userCompany{
color: #B7B9CB;
margin:4px;
font-size: 14px;
}

@media screen and (max-width: 955px) {
.detailsContainer { 
      width: 100%;
  }
}
.dangerText{
  color : rgb(139, 32, 32) !important
}

.emailInputBox{
  margin-top : 1rem !important;
  margin-bottom : 1rem !important;
}

.oneRemMarginTopAndoneRemBottomSeperatorCopy{
  margin-top:0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.updatePasswordTitle{
  margin-bottom: 0.75rem !important;
}

.profileEmail {
  color: #555555;
  font-weight: 400;
  margin: 4px;
  font-size: 12px;
}
.imgWrapperuser{
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.imgContaineruser{
  position: relative;
  width: 144px;
  height: 144px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #919EAB33;
}
.circlebtnuser{
  border: 2px solid #f8f8f8;
  background-color: white;
  position:absolute;
  right:-5px;
  height:30px;
  width:30px;
  border-radius:50%;
  top:5px;
}
.profileImageNote{
  text-align: center;
  max-width: 175px
}
.profileContainer{
  display: 'flex';
  flex-direction: column;
}
.imageUploadContainer{
  background-color: rgba(20, 26, 33, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  gap: 8px;
}
.switchLabel{
  gap: 10px;
}
.profileContainerButtonWrapper{
  display: flex;
  justify-content: center;
}
.accountDetailsButtonWrapper{
  display: flex;
  align-items: center;
}
.passwordDetailsButtonWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.profileParentContainer{
  padding-top: 20px;
  padding-bottom: 20px;
}

/*---ProfileTab End---*/

/*---AppInfo Start---*/

.projectPicOuterBorder{
  /* border : 1px solid lightgray; */
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
}

.greyBackground{
  background-color: lightgray;
  display: flex;
  padding:1rem;
}

.textFeildsContainer{
  background-color: white !important;
}

.defaultImg{
width:400px;
object-fit: cover;

}
.imgGrid{
text-align: center;
}
.appSubDetails {
  color: #43425D;
  opacity: 0.9;
  display:flex;
  margin-top :0.5rem;
  font-size: 0.9rem;
 
}
.appSubDetails span{
  display:flex;
  margin-right: 10px;
  align-items: center;;
}

.topIcon{
  height:0.8rem!important;
  font-size: 1rem!important;
  margin-right:5px;
}
.appTitle{
    padding-top:5px;
}
.appIconsTop{
    padding-top:8px;
    padding-bottom: 4px;
}
.p-t-20{
    padding-top:20px;
}
.appImg{
    vertical-align: middle;
}

.topinfoGrid{
  font-size: 14px;
  padding: 0px 5px 0px 0px;
}
.appGitName{
    color:#43425D!important;
}
/*---AppInfo End---*/

/*---CreateApp Start---*/
.plugindis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


  .plugindiv{
      padding: 10px;
  }
  .pluginimg{
    /* max-width: -webkit-fill-available;
    max-height: fit-content; */
    object-fit: scale-down;
    height: 100%;
    width: 100%;
}
.pluginmgdiv{
    padding:10px;
}
/*---CreateApp End---*/

/*---ClusterList Start---*/
.clusterListContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
  margin: 20px 0;
}

.clusterListContainer .title-container {
  flex-grow: 0;
  max-width: 30%;
  flex-basis: 30%;
  gap: 8px;
}

.clusterListContainer .filter-container {
  flex-grow: 0;
  max-width: 70%;
  flex-basis: 70%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  flex-wrap: nowrap;
}

.clusterListContainer .search-container {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
}

.clusterListContainer .import-container {
  flex-grow: 0;
  max-width: 41.67%;
  flex-basis: 41.67%;
  display: flex;
  column-gap: 10px;
  flex-wrap: nowrap;
}

@media (max-width: 1080px) {
  .makeStyles-contentShift-5 .clusterListContainer .title-container {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }

  .makeStyles-contentShift-5 .clusterListContainer .filter-container {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }

  .makeStyles-contentShift-5 .clusterListContainer .search-container {
    flex-grow: 0;
    max-width: 49%;
    flex-basis: 49%;
  }

  .makeStyles-contentShift-5 .clusterListContainer .import-container {
    flex-grow: 0;
    max-width: 49%;
    flex-basis: 49%;
  }
}

@media (max-width: 960px) {
  .clusterListContainer .title-container {
    flex-grow: 0;
    max-width: 35%;
    flex-basis: 35%;
  }

  .clusterListContainer .filter-container {
    flex-grow: 0;
    max-width: 65%;
    flex-basis: 65%;
  }

  .clusterListContainer .search-container {
    flex-grow: 0;
    max-width: 49%;
    flex-basis: 49%;
  }

  .clusterListContainer .import-container {
    flex-grow: 0;
    max-width: 49%;
    flex-basis: 49%;
  }

  .makeStyles-contentShift-5 .clusterListContainer .search-container {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }

  .makeStyles-contentShift-5 .clusterListContainer .import-container {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (max-width: 600px) {
  .clusterListContainer .title-container {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }

  .clusterListContainer .filter-container {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .clusterListContainer .search-container {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }

  .clusterListContainer .import-container {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.clusterActiveForm .MuiFormControlLabel-root{
  margin-left: 0px !important;
}
/*---ClusterList End---*/

/*---CronJob start---*/
.jobname{
  margin-top: 5px;
  font-size: 18px;
  color: #357dfd;
  margin-bottom: 5px;
  font-weight: 500;
  }
  .muiInput{
      padding:14px 14px!important;
      border-radius:4px;
  }
  
  .ant-input{
    padding: 10.5px 14px!important;
  
  }
  .codeDiv{
  width:100%;
  margin: 24px auto;
  padding: 16px;
  overflow: auto;
  border-radius: 4px;
  background-color: #272c34;
  color:white!important;
  padding-top:20px;
  }
  .addcodeDiv{
    width:100%;
    margin: 24px auto;
    padding: 16px;
    overflow: auto;
    border-radius: 4px;
    background-color: white;
    padding-top:20px;
    }
  .advancediv{
  padding-top:20px;
  padding-bottom:20px;
  }
  .jobNav{
    vertical-align: top;
    margin-left:10px;
    font-weight: bold;
  
  }
  .codeDiv  .MuiInputBase-input
  {
      color:white!important;
  }
  .p-l-0{
    padding-left:0px!important;
  }
  .p-24{
    padding: 24px !important;
  }
  
  
  [class^=ant-], [class*= ant-], [class^=ant-] *, [class*= ant-] *, [class^=ant-] *::before, [class*= ant-] *::before, [class^=ant-] *::after, [class*= ant-] *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  }
  
  .ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
  
  
  .ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  
  .right{
    float:right;
  }
  
  .ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
  }
  
  .ant-btn-primary {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  
  .ant-btn, .ant-btn:active, .ant-btn:focus {
  outline: 0;
  }
  
  .ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-color: #d9d9d9;
  }
  
  
  .ant-select-single .ant-select-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
  @supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 30px;
  }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  pointer-events: none;
  }
  .ant-select-single .ant-select-selector::after,
  .ant-select-single .ant-select-selector .ant-select-selection-item::after,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
  opacity: 0.4;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 32px;
  padding: 0 11px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
  }
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
  }
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
  }
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
  }
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
  }
  /**
  * Do not merge `height` & `line-height` under style with `selection` & `search`,
  * since chrome may update to redesign with its align logic.
  */
  .ant-select-multiple .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px 4px;
  }
  .ant-select-multiple .ant-select-selector input {
  cursor: pointer;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector input {
  cursor: auto;
  }
  .ant-select-focused.ant-select-multiple .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector input {
  cursor: not-allowed;
  }
  .ant-select-multiple .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
  }
  .ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
  }
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
  }
  .ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-right: 4px;
  margin-bottom: 2px;
  padding: 0 4px 0 8px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  -webkit-transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }
  .ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 12px;
  line-height: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  }
  .ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
  }
  .ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
  }
  .ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
  }
  .ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: -0.2em;
  }
  .ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
  }
  .ant-select-multiple .ant-select-selection-search {
  position: relative;
  margin-left: 0.5px;
  }
  .ant-select-multiple .ant-select-selection-search-input,
  .ant-select-multiple .ant-select-selection-search-mirror {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.5715;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
  .ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 3px;
  }
  .ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: nowrap;
  visibility: hidden;
  }
  .ant-select-multiple .ant-select-selection-search:first-child .ant-select-selection-search-input {
  margin-left: 6.5px;
  }
  .ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 33px;
  line-height: 33px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 17px;
  line-height: 17px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search:first-child .ant-select-selection-search-input {
  margin-left: 3px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
  }
  .ant-select-disabled .ant-select-selection-item-remove {
  display: none;
  }
  /* Reset search input style */
  .ant-select {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  }
  .ant-select-selection-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  }
  @media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
  -ms-flex: auto;
  flex: auto;
  }
  }
  .ant-select-selection-placeholder {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.4;
  }
  @media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
  -ms-flex: auto;
  flex: auto;
  }
  }
  .ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
  }
  .ant-select-arrow > * {
  line-height: 1;
  }
  .ant-select-arrow svg {
  display: inline-block;
  }
  .ant-select-arrow::before {
  display: none;
  }
  .ant-select-arrow .ant-select-arrow-icon {
  display: block;
  }
  .ant-select-arrow .anticon {
  vertical-align: top;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .ant-select-arrow .anticon > svg {
  vertical-align: top;
  }
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
  }
  .ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: color 0.3s ease, opacity 0.15s ease;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
  }
  .ant-select-clear::before {
  display: block;
  }
  .ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
  }
  .ant-select:hover .ant-select-clear {
  opacity: 1;
  }
  .ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  }
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  }
  .ant-select-dropdown-hidden {
  display: none;
  }
  .ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  }
  .ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
  }
  .ant-select-item-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  }
  .ant-select-item-option-content {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  }
  .ant-select-item-option-state {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #e6f7ff;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #1890ff;
  }
  .ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  }
  .ant-select-item-option-grouped {
  padding-left: 24px;
  }
  .ant-select-lg {
  font-size: 16px;
  }
  .ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  }
  .ant-select-rtl {
  direction: rtl;
  }
  .ant-select-rtl .ant-select-arrow {
  right: initial;
  left: 11px;
  }
  .ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px;
  }
  .ant-select-dropdown-rtl {
  direction: rtl;
  }
  .ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
  }
  .ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  margin-right: 0;
  margin-left: 4px;
  padding: 0 8px 0 4px;
  text-align: right;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-search {
  margin-right: 0.5px;
  margin-left: 4px;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
  }
  .ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
  }
  .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
  }
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
  }
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
  }
  .ant-select-rtl.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
  }
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 0;
  }
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
  }
  
  
  .ant-drawer-rtl {
  direction: rtl;
  }
  .ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-form-item .ant-mentions,
  .ant-form-item textarea.ant-input {
  height: auto;
  }
  .ant-form-item .ant-upload {
  background: transparent;
  }
  .ant-form-item .ant-upload.ant-upload-drag {
  background: #fafafa;
  }
  .ant-form-item input[type='radio'],
  .ant-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
  }
  .ant-form-item .ant-radio-inline,
  .ant-form-item .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
  }
  .ant-form-item .ant-radio-inline:first-child,
  .ant-form-item .ant-checkbox-inline:first-child {
  margin-left: 0;
  }
  .ant-form-item .ant-checkbox-vertical,
  .ant-form-item .ant-radio-vertical {
  display: block;
  }
  .ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
  .ant-form-item .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
  }
  .ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
  }
  .ant-form-item .ant-input-number-handler-wrap {
  z-index: 2;
  }
  .ant-form-item .ant-select,
  .ant-form-item .ant-cascader-picker {
  width: 100%;
  }
  .ant-form-item .ant-input-group .ant-select,
  .ant-form-item .ant-input-group .ant-cascader-picker {
  width: auto;
  }
  .ant-form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  }
  .ant-form-inline .ant-form-item {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
  }
  .ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
  }
  .ant-form-inline .ant-form-item > .ant-form-item-label,
  .ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
  }
  .ant-form-inline .ant-form-item > .ant-form-item-label {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  }
  .ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
  }
  .ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
  }
  .ant-form-horizontal .ant-form-item-label {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  }
  .ant-form-horizontal .ant-form-item-control {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  }
  .ant-form-vertical .ant-form-item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  }
  .ant-form-vertical .ant-form-item-label > label {
  height: auto;
  }
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  }
  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
  }
  .ant-form-vertical .ant-form-item-label > label::after,
  .ant-col-24.ant-form-item-label > label::after,
  .ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
  }
  .ant-form-rtl.ant-form-vertical .ant-form-item-label,
  .ant-form-rtl.ant-col-24.ant-form-item-label,
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
  }
  @media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
  margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
  display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
  text-align: right;
  }
  .ant-form .ant-form-item {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
  margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
  display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
  text-align: right;
  }
  }
  @media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
  margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
  display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
  text-align: right;
  }
  }
  @media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
  margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
  display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
  text-align: right;
  }
  }
  @media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
  margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
  display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
  text-align: right;
  }
  }
  @media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
  }
  }
  .ant-form-item {
  /* Some non-status related component style is in `components.less` */
  }
  .ant-form-item-has-feedback .ant-input {
  padding-right: 24px;
  }
  .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
  }
  .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
  }
  .ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
  }
  .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
  .ant-form-item-has-feedback > .ant-select .ant-select-clear,
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: 32px;
  }
  .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
  }
  .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 19px;
  }
  .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: 32px;
  }
  .ant-form-item-has-feedback .ant-picker {
  padding-right: 29.2px;
  }
  .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 29.2px;
  }
  .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 25.2px;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  ::-moz-selection {
    color: #fff;
    background: #1890ff;
  }
  ::selection {
    color: #fff;
    background: #1890ff;
  }
  .clearfix::before {
    display: table;
    content: '';
  }
  .clearfix::after {
    display: table;
    clear: both;
    content: '';
  }
  .anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .anticon > * {
    line-height: 1;
  }
  .anticon svg {
    display: inline-block;
  }
  .anticon::before {
    display: none;
  }
  .anticon .anticon-icon {
    display: block;
  }
  .anticon[tabindex] {
    cursor: pointer;
  }
  .anticon-spin::before {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
  }
  .anticon-spin {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
  }
  .fade-enter,
  .fade-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .fade-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .fade-enter.fade-enter-active,
  .fade-appear.fade-appear-active {
    -webkit-animation-name: antFadeIn;
            animation-name: antFadeIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .fade-leave.fade-leave-active {
    -webkit-animation-name: antFadeOut;
            animation-name: antFadeOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .fade-enter,
  .fade-appear {
    opacity: 0;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .fade-leave {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  @-webkit-keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .move-up-enter,
  .move-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .move-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .move-up-enter.move-up-enter-active,
  .move-up-appear.move-up-appear-active {
    -webkit-animation-name: antMoveUpIn;
            animation-name: antMoveUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .move-up-leave.move-up-leave-active {
    -webkit-animation-name: antMoveUpOut;
            animation-name: antMoveUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .move-up-enter,
  .move-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .move-down-enter,
  .move-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .move-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .move-down-enter.move-down-enter-active,
  .move-down-appear.move-down-appear-active {
    -webkit-animation-name: antMoveDownIn;
            animation-name: antMoveDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .move-down-leave.move-down-leave-active {
    -webkit-animation-name: antMoveDownOut;
            animation-name: antMoveDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .move-down-enter,
  .move-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .move-left-enter,
  .move-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .move-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .move-left-enter.move-left-enter-active,
  .move-left-appear.move-left-appear-active {
    -webkit-animation-name: antMoveLeftIn;
            animation-name: antMoveLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .move-left-leave.move-left-leave-active {
    -webkit-animation-name: antMoveLeftOut;
            animation-name: antMoveLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .move-left-enter,
  .move-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .move-right-enter,
  .move-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .move-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .move-right-enter.move-right-enter-active,
  .move-right-appear.move-right-appear-active {
    -webkit-animation-name: antMoveRightIn;
            animation-name: antMoveRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .move-right-leave.move-right-leave-active {
    -webkit-animation-name: antMoveRightOut;
            animation-name: antMoveRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .move-right-enter,
  .move-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .move-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  @-webkit-keyframes antMoveDownIn {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveDownIn {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveDownOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveDownOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveLeftIn {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveLeftIn {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveLeftOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveLeftOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveRightIn {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveRightIn {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveRightOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveRightOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveUpIn {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveUpIn {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveUpOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveUpOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  [ant-click-animating='true'],
  [ant-click-animating-without-extra-node='true'] {
    position: relative;
  }
  html {
    --antd-wave-shadow-color: #1890ff;
    --scroll-bar: 0;
  }
  [ant-click-animating-without-extra-node='true']::after,
  .ant-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    -webkit-box-shadow: 0 0 0 0 #1890ff;
            box-shadow: 0 0 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
    opacity: 0.2;
    -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
            animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    content: '';
    pointer-events: none;
  }
  @-webkit-keyframes waveEffect {
    100% {
      -webkit-box-shadow: 0 0 0 #1890ff;
              box-shadow: 0 0 0 #1890ff;
      -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
              box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
  }
  @keyframes waveEffect {
    100% {
      -webkit-box-shadow: 0 0 0 #1890ff;
              box-shadow: 0 0 0 #1890ff;
      -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
              box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    }
  }
  @-webkit-keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
  .slide-up-enter,
  .slide-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .slide-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .slide-up-enter.slide-up-enter-active,
  .slide-up-appear.slide-up-appear-active {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .slide-up-leave.slide-up-leave-active {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .slide-up-enter,
  .slide-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .slide-down-enter,
  .slide-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .slide-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .slide-down-enter.slide-down-enter-active,
  .slide-down-appear.slide-down-appear-active {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .slide-down-leave.slide-down-leave-active {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .slide-down-enter,
  .slide-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .slide-left-enter,
  .slide-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .slide-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .slide-left-enter.slide-left-enter-active,
  .slide-left-appear.slide-left-appear-active {
    -webkit-animation-name: antSlideLeftIn;
            animation-name: antSlideLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .slide-left-leave.slide-left-leave-active {
    -webkit-animation-name: antSlideLeftOut;
            animation-name: antSlideLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .slide-left-enter,
  .slide-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .slide-right-enter,
  .slide-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .slide-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .slide-right-enter.slide-right-enter-active,
  .slide-right-appear.slide-right-appear-active {
    -webkit-animation-name: antSlideRightIn;
            animation-name: antSlideRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .slide-right-leave.slide-right-leave-active {
    -webkit-animation-name: antSlideRightOut;
            animation-name: antSlideRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .slide-right-enter,
  .slide-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .slide-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @-webkit-keyframes antSlideUpIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideUpIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideDownIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes antSlideDownIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideDownOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  @keyframes antSlideDownOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideLeftIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideLeftIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideLeftOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideLeftOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideRightIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideRightIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideRightOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideRightOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
  }
  .zoom-enter,
  .zoom-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-enter.zoom-enter-active,
  .zoom-appear.zoom-appear-active {
    -webkit-animation-name: antZoomIn;
            animation-name: antZoomIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .zoom-leave.zoom-leave-active {
    -webkit-animation-name: antZoomOut;
            animation-name: antZoomOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .zoom-enter,
  .zoom-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-big-enter,
  .zoom-big-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-big-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-big-enter.zoom-big-enter-active,
  .zoom-big-appear.zoom-big-appear-active {
    -webkit-animation-name: antZoomBigIn;
            animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .zoom-big-leave.zoom-big-leave-active {
    -webkit-animation-name: antZoomBigOut;
            animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .zoom-big-enter,
  .zoom-big-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-big-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-big-fast-enter,
  .zoom-big-fast-appear {
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-big-fast-leave {
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-big-fast-enter.zoom-big-fast-enter-active,
  .zoom-big-fast-appear.zoom-big-fast-appear-active {
    -webkit-animation-name: antZoomBigIn;
            animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .zoom-big-fast-leave.zoom-big-fast-leave-active {
    -webkit-animation-name: antZoomBigOut;
            animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .zoom-big-fast-enter,
  .zoom-big-fast-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-big-fast-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-up-enter,
  .zoom-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-up-enter.zoom-up-enter-active,
  .zoom-up-appear.zoom-up-appear-active {
    -webkit-animation-name: antZoomUpIn;
            animation-name: antZoomUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .zoom-up-leave.zoom-up-leave-active {
    -webkit-animation-name: antZoomUpOut;
            animation-name: antZoomUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .zoom-up-enter,
  .zoom-up-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-down-enter,
  .zoom-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-down-enter.zoom-down-enter-active,
  .zoom-down-appear.zoom-down-appear-active {
    -webkit-animation-name: antZoomDownIn;
            animation-name: antZoomDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .zoom-down-leave.zoom-down-leave-active {
    -webkit-animation-name: antZoomDownOut;
            animation-name: antZoomDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .zoom-down-enter,
  .zoom-down-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-left-enter,
  .zoom-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-left-enter.zoom-left-enter-active,
  .zoom-left-appear.zoom-left-appear-active {
    -webkit-animation-name: antZoomLeftIn;
            animation-name: antZoomLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .zoom-left-leave.zoom-left-leave-active {
    -webkit-animation-name: antZoomLeftOut;
            animation-name: antZoomLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .zoom-left-enter,
  .zoom-left-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .zoom-right-enter,
  .zoom-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .zoom-right-enter.zoom-right-enter-active,
  .zoom-right-appear.zoom-right-appear-active {
    -webkit-animation-name: antZoomRightIn;
            animation-name: antZoomRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .zoom-right-leave.zoom-right-leave-active {
    -webkit-animation-name: antZoomRightOut;
            animation-name: antZoomRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .zoom-right-enter,
  .zoom-right-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .zoom-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  @-webkit-keyframes antZoomIn {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomIn {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes antZoomOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
  }
  @keyframes antZoomOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomBigIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomBigIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes antZoomBigOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
  }
  @keyframes antZoomBigOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomUpIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
  }
  @keyframes antZoomUpIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
  }
  @-webkit-keyframes antZoomUpOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
  }
  @keyframes antZoomUpOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomLeftIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
  }
  @keyframes antZoomLeftIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
  }
  @-webkit-keyframes antZoomLeftOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomLeftOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomRightIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
  }
  @keyframes antZoomRightIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
  }
  @-webkit-keyframes antZoomRightOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomRightOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomDownIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  @keyframes antZoomDownIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  @-webkit-keyframes antZoomDownOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  @keyframes antZoomDownOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  .ant-motion-collapse-legacy {
    overflow: hidden;
  }
  .ant-motion-collapse-legacy-active {
    -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  .ant-motion-collapse {
    overflow: hidden;
    -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-affix {
    position: fixed;
    z-index: 10;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-alert {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    max-height: 1000vh;
    padding: 8px 15px 8px 37px;
    word-wrap: break-word;
    border-radius: 2px;
  }
  .ant-alert.ant-alert-no-icon {
    padding: 8px 15px;
  }
  .ant-alert.ant-alert-no-icon .ant-alert-close-icon {
    top: 12.0005px;
  }
  .ant-alert.ant-alert-closable {
    padding-right: 30px;
  }
  .ant-alert-icon {
    position: absolute;
    top: 12.0005px;
    left: 16px;
  }
  .ant-alert-description {
    display: none;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-alert-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }
  .ant-alert-success .ant-alert-icon {
    color: #52c41a;
  }
  .ant-alert-info {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
  }
  .ant-alert-info .ant-alert-icon {
    color: #1890ff;
  }
  .ant-alert-warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
  }
  .ant-alert-warning .ant-alert-icon {
    color: #faad14;
  }
  .ant-alert-error {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
  }
  .ant-alert-error .ant-alert-icon {
    color: #ff4d4f;
  }
  .ant-alert-error .ant-alert-description > pre {
    margin: 0;
    padding: 0;
  }
  .ant-alert-close-icon {
    position: absolute;
    top: 12.0005px;
    right: 16px;
    padding: 0;
    overflow: hidden;
    font-size: 12px;
    line-height: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .ant-alert-close-icon .anticon-close {
    color: rgba(0, 0, 0, 0.45);
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .ant-alert-close-icon .anticon-close:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .ant-alert-close-text {
    color: rgba(0, 0, 0, 0.45);
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .ant-alert-close-text:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .ant-alert-with-description {
    position: relative;
    padding: 15px 15px 15px 63px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5715;
    border-radius: 2px;
  }
  .ant-alert-with-description.ant-alert-no-icon {
    padding: 15px 15px;
  }
  .ant-alert-with-description .ant-alert-icon {
    position: absolute;
    top: 15px;
    left: 24px;
    font-size: 24px;
  }
  .ant-alert-with-description .ant-alert-close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 14px;
    cursor: pointer;
  }
  .ant-alert-with-description .ant-alert-message {
    display: block;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }
  .ant-alert-message {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-alert-with-description .ant-alert-description {
    display: block;
  }
  .ant-alert.ant-alert-closing {
    max-height: 0;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-alert-slide-up-leave {
    -webkit-animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .ant-alert-banner {
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
  }
  @-webkit-keyframes antAlertSlideUpIn {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antAlertSlideUpIn {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antAlertSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antAlertSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  .ant-alert.ant-alert-rtl {
    padding: 8px 37px 8px 15px;
    direction: rtl;
  }
  .ant-alert-rtl.ant-alert.ant-alert-no-icon {
    padding: 8px 15px;
  }
  .ant-alert.ant-alert-rtl.ant-alert.ant-alert-closable {
    padding-right: 37px;
    padding-left: 30px;
  }
  .ant-alert.ant-alert-rtl.ant-alert.ant-alert-no-icon.ant-alert-closable {
    padding-right: 15px;
    padding-left: 30px;
  }
  .ant-alert-rtl .ant-alert-icon {
    right: 16px;
    left: auto;
  }
  .ant-alert-rtl .ant-alert-close-icon {
    right: auto;
    left: 16px;
  }
  .ant-alert.ant-alert-rtl.ant-alert-with-description,
  .ant-alert.ant-alert-rtl.ant-alert-with-description.ant-alert-closable {
    padding: 15px 63px 15px 15px;
  }
  .ant-alert.ant-alert-rtl.ant-alert-with-description.ant-alert-no-icon {
    padding: 15px;
  }
  .ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
    right: 24px;
    left: auto;
  }
  .ant-alert-rtl.ant-alert-with-description .ant-alert-close-icon {
    right: auto;
    left: 16px;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-anchor {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    padding-left: 2px;
  }
  .ant-anchor-wrapper {
    margin-left: -4px;
    padding-left: 4px;
    overflow: auto;
    background-color: #fff;
  }
  .ant-anchor-ink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  .ant-anchor-ink::before {
    position: relative;
    display: block;
    width: 2px;
    height: 100%;
    margin: 0 auto;
    background-color: #f0f0f0;
    content: ' ';
  }
  .ant-anchor-ink-ball {
    position: absolute;
    left: 50%;
    display: none;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 2px solid #1890ff;
    border-radius: 8px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: top 0.3s ease-in-out;
    transition: top 0.3s ease-in-out;
  }
  .ant-anchor-ink-ball.visible {
    display: inline-block;
  }
  .ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
    display: none;
  }
  .ant-anchor-link {
    padding: 7px 0 7px 16px;
    line-height: 1.143;
  }
  .ant-anchor-link-title {
    position: relative;
    display: block;
    margin-bottom: 6px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-anchor-link-title:only-child {
    margin-bottom: 0;
  }
  .ant-anchor-link-active > .ant-anchor-link-title {
    color: #1890ff;
  }
  .ant-anchor-link .ant-anchor-link {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .ant-anchor-rtl {
    direction: rtl;
  }
  .ant-anchor-rtl.ant-anchor-wrapper {
    margin-right: -4px;
    margin-left: 0;
    padding-right: 4px;
    padding-left: 0;
  }
  .ant-anchor-rtl .ant-anchor-ink {
    right: 0;
    left: auto;
  }
  .ant-anchor-rtl .ant-anchor-ink-ball {
    right: 50%;
    left: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  .ant-anchor-rtl .ant-anchor-link {
    padding: 7px 16px 7px 0;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-select-auto-complete {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
  }
  .ant-select-auto-complete .ant-select-clear {
    right: 13px;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-select-single .ant-select-selector {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    left: 11px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    width: 100%;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 30px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  @supports (-moz-appearance: meterbar) {
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 30px;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    pointer-events: none;
  }
  .ant-select-single .ant-select-selector::after,
  .ant-select-single .ant-select-selector .ant-select-selection-item::after,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 25px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    opacity: 0.4;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 32px;
    padding: 0 11px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer;
  }
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
  }
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: auto;
  }
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: not-allowed;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 30px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
    line-height: 30px;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector::after {
    display: none;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
    position: static;
    width: 100%;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
    position: absolute;
    right: 0;
    left: 0;
    padding: 0 11px;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
    display: none;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 38px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 24px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 22px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 22px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
    right: 7px;
    left: 7px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 7px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
    right: 28px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 21px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
  }
  /**
   * Do not merge `height` & `line-height` under style with `selection` & `search`,
   * since chrome may update to redesign with its align logic.
   */
  .ant-select-multiple .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 1px 4px;
  }
  .ant-select-multiple .ant-select-selector input {
    cursor: pointer;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    cursor: text;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector input {
    cursor: auto;
  }
  .ant-select-focused.ant-select-multiple .ant-select-selector {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector input {
    cursor: not-allowed;
  }
  .ant-select-multiple .ant-select-selector .ant-select-selection-search-input {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    cursor: text;
  }
  .ant-select-multiple .ant-select-selector::after {
    display: inline-block;
    width: 0;
    margin: 2px 0;
    line-height: 24px;
    content: '\a0';
  }
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 24px;
  }
  .ant-select-multiple .ant-select-selection-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-right: 4px;
    margin-bottom: 2px;
    padding: 0 4px 0 8px;
    line-height: 22px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    cursor: default;
    -webkit-transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .ant-select-multiple .ant-select-selection-item-content {
    display: inline-block;
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
    font-size: 12px;
    line-height: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
  }
  .ant-select-multiple .ant-select-selection-item-remove > * {
    line-height: 1;
  }
  .ant-select-multiple .ant-select-selection-item-remove svg {
    display: inline-block;
  }
  .ant-select-multiple .ant-select-selection-item-remove::before {
    display: none;
  }
  .ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
    display: block;
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: -0.2em;
  }
  .ant-select-multiple .ant-select-selection-item-remove:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .ant-select-multiple .ant-select-selection-search {
    position: relative;
    margin-left: 0.5px;
  }
  .ant-select-multiple .ant-select-selection-search-input,
  .ant-select-multiple .ant-select-selection-search-mirror {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 1.5715;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-select-multiple .ant-select-selection-search-input {
    width: 100%;
    min-width: 3px;
  }
  .ant-select-multiple .ant-select-selection-search-mirror {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    white-space: nowrap;
    visibility: hidden;
  }
  .ant-select-multiple .ant-select-selection-search:first-child .ant-select-selection-search-input {
    margin-left: 6.5px;
  }
  .ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 11px;
    left: 11px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selector::after {
    line-height: 32px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 32px;
    line-height: 30px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search {
    height: 33px;
    line-height: 33px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
    height: 32px;
    line-height: 30px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selector::after {
    line-height: 16px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 16px;
    line-height: 14px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search {
    height: 17px;
    line-height: 17px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
    height: 16px;
    line-height: 14px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    left: 7px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search:first-child .ant-select-selection-search-input {
    margin-left: 3px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 32px;
    line-height: 32px;
  }
  .ant-select-disabled .ant-select-selection-item-remove {
    display: none;
  }
  /* Reset search input style */
  .ant-select {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-select-selection-item {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media all and (-ms-high-contrast: none) {
    .ant-select-selection-item *::-ms-backdrop,
    .ant-select-selection-item {
      -ms-flex: auto;
          flex: auto;
    }
  }
  .ant-select-selection-placeholder {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 0.4;
  }
  @media all and (-ms-high-contrast: none) {
    .ant-select-selection-placeholder *::-ms-backdrop,
    .ant-select-selection-placeholder {
      -ms-flex: auto;
          flex: auto;
    }
  }
  .ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 53%;
    right: 11px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
  }
  .ant-select-arrow > * {
    line-height: 1;
  }
  .ant-select-arrow svg {
    display: inline-block;
  }
  .ant-select-arrow::before {
    display: none;
  }
  .ant-select-arrow .ant-select-arrow-icon {
    display: block;
  }
  .ant-select-arrow .anticon {
    vertical-align: top;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .ant-select-arrow .anticon > svg {
    vertical-align: top;
  }
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: auto;
  }
  .ant-select-clear {
    position: absolute;
    top: 50%;
    right: 11px;
    z-index: 1;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: color 0.3s ease, opacity 0.15s ease;
    transition: color 0.3s ease, opacity 0.15s ease;
    text-rendering: auto;
  }
  .ant-select-clear::before {
    display: block;
  }
  .ant-select-clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-select:hover .ant-select-clear {
    opacity: 1;
  }
  .ant-select-dropdown {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 4px 0;
    overflow: hidden;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
            box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .ant-select-dropdown-hidden {
    display: none;
  }
  .ant-select-dropdown-empty {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-item-empty {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-item {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
  }
  .ant-select-item-group {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    cursor: default;
  }
  .ant-select-item-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .ant-select-item-option-content {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-select-item-option-state {
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
    background-color: #e6f7ff;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #1890ff;
  }
  .ant-select-item-option-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-select-item-option-grouped {
    padding-left: 24px;
  }
  .ant-select-lg {
    font-size: 16px;
  }
  .ant-select-borderless .ant-select-selector {
    background-color: transparent !important;
    border-color: transparent !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .ant-select-rtl {
    direction: rtl;
  }
  .ant-select-rtl .ant-select-arrow {
    right: initial;
    left: 11px;
  }
  .ant-select-rtl .ant-select-clear {
    right: initial;
    left: 11px;
  }
  .ant-select-dropdown-rtl {
    direction: rtl;
  }
  .ant-select-dropdown-rtl .ant-select-item-option-grouped {
    padding-right: 24px;
    padding-left: 12px;
  }
  .ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 4px;
    padding-left: 24px;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-item {
    margin-right: 0;
    margin-left: 4px;
    padding: 0 8px 0 4px;
    text-align: right;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
    margin-right: 0;
    margin-left: 4px;
    text-align: right;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-search {
    margin-right: 0.5px;
    margin-left: 4px;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
    right: 0;
    left: auto;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
    right: 11px;
    left: auto;
  }
  .ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    right: 7px;
  }
  .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    right: 0;
    left: 9px;
    text-align: right;
  }
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 11px;
    left: 25px;
  }
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0;
    padding-left: 18px;
  }
  .ant-select-rtl.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
  }
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
    right: 0;
  }
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0;
    padding-left: 21px;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
    color: #ff4d4f;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
    color: #fff;
    background-color: #ff4d4f;
  }
  .ant-dropdown {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;
  }
  .ant-dropdown::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: -7px;
    left: -7px;
    z-index: -9999;
    opacity: 0.0001;
    content: ' ';
  }
  .ant-dropdown-wrap {
    position: relative;
  }
  .ant-dropdown-wrap .ant-btn > .anticon-down {
    display: inline-block;
    font-size: 10px;
  }
  .ant-dropdown-wrap .anticon-down::before {
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
  .ant-dropdown-wrap-open .anticon-down::before {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .ant-dropdown-hidden,
  .ant-dropdown-menu-hidden {
    display: none;
  }
  .ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
  .ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
  .ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
    padding-bottom: 10px;
  }
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
    padding-top: 10px;
  }
  .ant-dropdown-arrow {
    position: absolute;
    z-index: 1;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
  .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    bottom: 6.2px;
    border-top-color: transparent;
    border-right-color: #fff;
    border-bottom-color: #fff;
    border-left-color: transparent;
    -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
            box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
  }
  .ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
    left: 16px;
  }
  .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    right: 16px;
  }
  .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    top: 6px;
    border-top-color: #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #fff;
    -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
            box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  }
  .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
  }
  .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
    left: 16px;
  }
  .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    right: 16px;
  }
  .ant-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
            box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-dropdown-menu-item-group-title {
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.45);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-dropdown-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .ant-dropdown-menu-submenu-popup ul,
  .ant-dropdown-menu-submenu-popup li {
    list-style: none;
  }
  .ant-dropdown-menu-submenu-popup ul {
    margin-right: 0.3em;
    margin-left: 0.3em;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    clear: both;
    margin: 0;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-dropdown-menu-item > .anticon:first-child,
  .ant-dropdown-menu-submenu-title > .anticon:first-child,
  .ant-dropdown-menu-item > span > .anticon:first-child,
  .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
    min-width: 12px;
    margin-right: 8px;
    font-size: 12px;
  }
  .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-submenu-title > a {
    display: block;
    margin: -5px -12px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-submenu-title > a:hover {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-dropdown-menu-item > .anticon + span > a,
  .ant-dropdown-menu-submenu-title > .anticon + span > a {
    color: rgba(0, 0, 0, 0.65);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-dropdown-menu-item > .anticon + span > a:hover,
  .ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected,
  .ant-dropdown-menu-item-selected > a,
  .ant-dropdown-menu-submenu-title-selected > a {
    color: #1890ff;
    background-color: #e6f7ff;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #f5f5f5;
  }
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-dropdown-menu-item-disabled:hover,
  .ant-dropdown-menu-submenu-title-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    height: 1px;
    margin: 4px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    position: absolute;
    right: 8px;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    margin-right: 0 !important;
    color: rgba(0, 0, 0, 0.45);
    font-style: normal;
    display: inline-block;
    font-size: 10px;
  }
  .ant-dropdown-menu-item-group-list {
    margin: 0 8px;
    padding: 0;
    list-style: none;
  }
  .ant-dropdown-menu-submenu-title {
    padding-right: 24px;
  }
  .ant-dropdown-menu-submenu-vertical {
    position: relative;
  }
  .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 100%;
    margin-left: 4px;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }
  .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
    color: #1890ff;
  }
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
  .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
  .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
  .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
  .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .ant-dropdown-trigger > .anticon.anticon-down,
  .ant-dropdown-link > .anticon.anticon-down,
  .ant-dropdown-button > .anticon.anticon-down {
    vertical-align: baseline;
    display: inline-block;
    font-size: 10px;
  }
  .ant-dropdown-button {
    white-space: nowrap;
  }
  .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-dropdown-menu-dark,
  .ant-dropdown-menu-dark .ant-dropdown-menu {
    background: #001529;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
    color: #fff;
    background: transparent;
  }
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
  .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
    color: #fff;
    background: #1890ff;
  }
  .ant-dropdown-rtl {
    direction: rtl;
  }
  .ant-dropdown-rtl.ant-dropdown::before {
    right: -7px;
    left: 0;
  }
  .ant-dropdown-menu-rtl {
    direction: rtl;
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
    direction: rtl;
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
    text-align: right;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    right: auto;
    left: 8px;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    margin-left: 0 !important;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
    padding-right: 12px;
    padding-left: 24px;
  }
  .ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    right: 100%;
    left: 0;
    margin-right: 4px;
    margin-left: 0;
  }
  
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-picker-calendar {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    background: #fff;
  }
  .ant-picker-calendar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding: 12px 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    min-width: 80px;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    min-width: 70px;
    margin-left: 8px;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    margin-left: 8px;
  }
  .ant-picker-calendar .ant-picker-panel {
    background: #fff;
    border: 0;
    border-top: 1px solid #f0f0f0;
    border-radius: 0;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
  .ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
    width: auto;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 8px 0;
  }
  .ant-picker-calendar .ant-picker-panel .ant-picker-content {
    width: 100%;
  }
  .ant-picker-calendar-mini {
    border-radius: 2px;
  }
  .ant-picker-calendar-mini .ant-picker-calendar-header {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-picker-calendar-mini .ant-picker-panel {
    border-radius: 0 0 2px 2px;
  }
  .ant-picker-calendar-mini .ant-picker-content {
    height: 256px;
  }
  .ant-picker-calendar-mini .ant-picker-content th {
    height: auto;
    padding: 0;
    line-height: 18px;
  }
  .ant-picker-calendar-full .ant-picker-panel {
    display: block;
    width: 100%;
    text-align: right;
    background: #fff;
    border: 0;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
    padding: 0;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    height: auto;
    padding: 0 12px 5px 0;
    line-height: 18px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
    display: none;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
    background: #f5f5f5;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
    display: none;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: #e6f7ff;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: #1890ff;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    display: block;
    width: auto;
    height: auto;
    margin: 0 4px;
    padding: 4px 8px 0;
    border: 0;
    border-top: 2px solid #f0f0f0;
    border-radius: 0;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    line-height: 24px;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    position: static;
    width: auto;
    height: 86px;
    overflow-y: auto;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5715;
    text-align: left;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-color: #1890ff;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: rgba(0, 0, 0, 0.65);
  }
  @media only screen and (max-width: 480px) {
    .ant-picker-calendar-header {
      display: block;
    }
    .ant-picker-calendar-header .ant-picker-calendar-year-select {
      width: 50%;
    }
    .ant-picker-calendar-header .ant-picker-calendar-month-select {
      width: calc(50% - 8px);
    }
    .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
      width: 100%;
      margin-top: 8px;
      margin-left: 0;
    }
    .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
      width: 50%;
      text-align: center;
    }
  }
  .ant-picker-calendar-rtl {
    direction: rtl;
  }
  .ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
    text-align: left;
  }
  .ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    padding: 0 0 5px 12px;
  }
  .ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    text-align: right;
  }
  
  
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-picker-panel {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    outline: none;
  }
  .ant-picker-panel-focused {
    border-color: #1890ff;
  }
  .ant-picker-decade-panel,
  .ant-picker-year-panel,
  .ant-picker-quarter-panel,
  .ant-picker-month-panel,
  .ant-picker-week-panel,
  .ant-picker-date-panel,
  .ant-picker-time-panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 280px;
  }
  .ant-picker-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-picker-header > * {
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
  }
  .ant-picker-header button {
    padding: 0;
    color: rgba(0, 0, 0, 0.25);
    line-height: 40px;
    background: transparent;
    border: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .ant-picker-header > button {
    min-width: 1.6em;
    font-size: 14px;
  }
  .ant-picker-header > button:hover {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-picker-header-view {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    font-weight: 500;
    line-height: 40px;
  }
  .ant-picker-header-view button {
    color: inherit;
    font-weight: inherit;
  }
  .ant-picker-header-view button:not(:first-child) {
    margin-left: 8px;
  }
  .ant-picker-header-view button:hover {
    color: #1890ff;
  }
  .ant-picker-prev-icon,
  .ant-picker-next-icon,
  .ant-picker-super-prev-icon,
  .ant-picker-super-next-icon {
    position: relative;
    display: inline-block;
    width: 7px;
    height: 7px;
  }
  .ant-picker-prev-icon::before,
  .ant-picker-next-icon::before,
  .ant-picker-super-prev-icon::before,
  .ant-picker-super-next-icon::before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 7px;
    height: 7px;
    border: 0 solid currentColor;
    border-width: 1.5px 0 0 1.5px;
    content: '';
  }
  .ant-picker-super-prev-icon::after,
  .ant-picker-super-next-icon::after {
    position: absolute;
    top: 4px;
    left: 4px;
    display: inline-block;
    width: 7px;
    height: 7px;
    border: 0 solid currentColor;
    border-width: 1.5px 0 0 1.5px;
    content: '';
  }
  .ant-picker-prev-icon,
  .ant-picker-super-prev-icon {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .ant-picker-next-icon,
  .ant-picker-super-next-icon {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .ant-picker-content {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  .ant-picker-content th,
  .ant-picker-content td {
    position: relative;
    min-width: 24px;
    font-weight: 400;
  }
  .ant-picker-content th {
    height: 30px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 30px;
  }
  .ant-picker-cell {
    padding: 3px 0;
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  .ant-picker-cell-in-view {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-picker-cell-disabled {
    cursor: not-allowed;
  }
  .ant-picker-cell::before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 1;
    height: 24px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    content: '';
  }
  .ant-picker-cell .ant-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    -webkit-transition: background 0.3s, border 0.3s;
    transition: background 0.3s, border 0.3s;
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: #f5f5f5;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: 1px solid #1890ff;
    border-radius: 2px;
    content: '';
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range {
    position: relative;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #e6f7ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #1890ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #e6f7ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
    left: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
    right: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    position: absolute;
    top: 50%;
    z-index: 0;
    height: 24px;
    border-top: 1px dashed #7ec1ff;
    border-bottom: 1px dashed #7ec1ff;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    content: '';
  }
  .ant-picker-cell-range-hover-start::after,
  .ant-picker-cell-range-hover-end::after,
  .ant-picker-cell-range-hover::after {
    right: 0;
    left: 2px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: #cbe6ff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
    border-radius: 2px 0 0 2px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
    border-radius: 0 2px 2px 0;
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: #cbe6ff;
    content: '';
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
    right: -7px;
    left: 0;
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    right: 0;
    left: -7px;
  }
  .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
    right: 50%;
  }
  .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
    left: 50%;
  }
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    left: 6px;
    border-left: 1px dashed #7ec1ff;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    right: 6px;
    border-right: 1px dashed #7ec1ff;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .ant-picker-cell-disabled {
    pointer-events: none;
  }
  .ant-picker-cell-disabled .ant-picker-cell-inner {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
  }
  .ant-picker-cell-disabled::before {
    background: #f5f5f5;
  }
  .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker-decade-panel .ant-picker-content,
  .ant-picker-year-panel .ant-picker-content,
  .ant-picker-quarter-panel .ant-picker-content,
  .ant-picker-month-panel .ant-picker-content {
    height: 264px;
  }
  .ant-picker-decade-panel .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-inner,
  .ant-picker-month-panel .ant-picker-cell-inner {
    padding: 0 8px;
  }
  .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
  .ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: #f5f5f5;
  }
  .ant-picker-quarter-panel .ant-picker-content {
    height: 56px;
  }
  .ant-picker-footer {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    min-width: 100%;
    line-height: 38px;
    text-align: center;
    border-bottom: 1px solid transparent;
  }
  .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid #f0f0f0;
  }
  .ant-picker-footer-extra {
    padding: 0 12px;
    line-height: 38px;
    text-align: left;
  }
  .ant-picker-footer-extra:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-picker-now {
    text-align: left;
  }
  .ant-picker-today-btn {
    color: #1890ff;
  }
  .ant-picker-today-btn:hover {
    color: #40a9ff;
  }
  .ant-picker-today-btn:active {
    color: #096dd9;
  }
  .ant-picker-today-btn.ant-picker-today-btn-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-picker-decade-panel .ant-picker-cell-inner {
    padding: 0 4px;
  }
  .ant-picker-decade-panel .ant-picker-cell::before {
    display: none;
  }
  .ant-picker-year-panel .ant-picker-body,
  .ant-picker-quarter-panel .ant-picker-body,
  .ant-picker-month-panel .ant-picker-body {
    padding: 0 8px;
  }
  .ant-picker-year-panel .ant-picker-cell-inner,
  .ant-picker-quarter-panel .ant-picker-cell-inner,
  .ant-picker-month-panel .ant-picker-cell-inner {
    width: 60px;
  }
  .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
    left: 14px;
    border-left: 1px dashed #7ec1ff;
    border-radius: 2px 0 0 2px;
  }
  .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
  .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
    right: 14px;
    border-right: 1px dashed #7ec1ff;
    border-radius: 0 2px 2px 0;
  }
  .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
    right: 14px;
    border-right: 1px dashed #7ec1ff;
    border-radius: 0 2px 2px 0;
  }
  .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
  .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
    left: 14px;
    border-left: 1px dashed #7ec1ff;
    border-radius: 2px 0 0 2px;
  }
  .ant-picker-week-panel .ant-picker-body {
    padding: 8px 12px;
  }
  .ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
  .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
    background: transparent !important;
  }
  .ant-picker-week-panel-row td {
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }
  .ant-picker-week-panel-row:hover td {
    background: #f5f5f5;
  }
  .ant-picker-week-panel-row-selected td,
  .ant-picker-week-panel-row-selected:hover td {
    background: #1890ff;
  }
  .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
  .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
    color: rgba(255, 255, 255, 0.5);
  }
  .ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
  .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #fff;
  }
  .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
  .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
    color: #fff;
  }
  .ant-picker-date-panel .ant-picker-body {
    padding: 8px 12px;
  }
  .ant-picker-date-panel .ant-picker-content {
    width: 252px;
  }
  .ant-picker-date-panel .ant-picker-content th {
    width: 36px;
  }
  .ant-picker-datetime-panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .ant-picker-datetime-panel .ant-picker-time-panel {
    border-left: 1px solid #f0f0f0;
  }
  .ant-picker-datetime-panel .ant-picker-date-panel,
  .ant-picker-datetime-panel .ant-picker-time-panel {
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .ant-picker-datetime-panel-active .ant-picker-date-panel,
  .ant-picker-datetime-panel-active .ant-picker-time-panel {
    opacity: 0.3;
  }
  .ant-picker-datetime-panel-active .ant-picker-date-panel-active,
  .ant-picker-datetime-panel-active .ant-picker-time-panel-active {
    opacity: 1;
  }
  .ant-picker-time-panel {
    width: auto;
    min-width: auto;
  }
  .ant-picker-time-panel .ant-picker-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    height: 224px;
  }
  .ant-picker-time-panel-column {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    width: 56px;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    text-align: left;
    list-style: none;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }
  .ant-picker-time-panel-column::after {
    display: block;
    height: 196px;
    content: '';
  }
  .ant-picker-datetime-panel .ant-picker-time-panel-column::after {
    height: 198px;
  }
  .ant-picker-time-panel-column:not(:first-child) {
    border-left: 1px solid #f0f0f0;
  }
  .ant-picker-time-panel-column-active {
    background: rgba(230, 247, 255, 0.2);
  }
  .ant-picker-time-panel-column:hover {
    overflow-y: auto;
  }
  .ant-picker-time-panel-column > li {
    margin: 0;
    padding: 0;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    display: block;
    width: 100%;
    height: 28px;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    line-height: 28px;
    text-align: center;
    border-radius: 0;
    cursor: pointer;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background: #f5f5f5;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #e6f7ff;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    cursor: not-allowed;
  }
  /* stylelint-disable-next-line */
  _:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
  :root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
  _:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
  :root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
    padding: 21px 0;
  }
  .ant-picker {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    padding: 4px 11px 4px;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, -webkit-box-shadow 0.3s;
    transition: border 0.3s, box-shadow 0.3s;
    transition: border 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-picker-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-picker.ant-picker-disabled {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-picker.ant-picker-disabled .ant-picker-suffix {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-picker.ant-picker-borderless {
    background-color: transparent !important;
    border-color: transparent !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .ant-picker-input {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
  .ant-picker-input > input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    min-width: 1px;
    height: auto;
    padding: 0;
    background: transparent;
    border: 0;
  }
  .ant-picker-input > input::-moz-placeholder {
    opacity: 1;
  }
  .ant-picker-input > input::-webkit-input-placeholder {
    color: #bfbfbf;
  }
  .ant-picker-input > input:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-picker-input > input::-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-picker-input > input::placeholder {
    color: #bfbfbf;
  }
  .ant-picker-input > input:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-picker-input > input:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-picker-input > input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-picker-input > input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input:focus,
  .ant-picker-input > input-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-picker-input > input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-picker-input > input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-picker-input > input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-picker-input > input-borderless,
  .ant-picker-input > input-borderless:hover,
  .ant-picker-input > input-borderless:focus,
  .ant-picker-input > input-borderless-focused,
  .ant-picker-input > input-borderless-disabled,
  .ant-picker-input > input-borderless[disabled] {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  textarea.ant-picker-input > input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    -webkit-transition: all 0.3s, height 0s;
    transition: all 0.3s, height 0s;
  }
  .ant-picker-input > input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-picker-input > input-sm {
    padding: 0px 7px;
  }
  .ant-picker-input > input:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-picker-input > input[disabled] {
    background: transparent;
  }
  .ant-picker-input:hover .ant-picker-clear {
    opacity: 1;
  }
  .ant-picker-input-placeholder > input {
    color: #bfbfbf;
  }
  .ant-picker-large {
    padding: 6.5px 11px 6.5px;
  }
  .ant-picker-large .ant-picker-input > input {
    font-size: 16px;
  }
  .ant-picker-small {
    padding: 0px 7px 0px;
  }
  .ant-picker-suffix {
    -ms-flex-item-align: center;
        align-self: center;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    pointer-events: none;
  }
  .ant-picker-suffix > * {
    vertical-align: top;
  }
  .ant-picker-clear {
    position: absolute;
    top: 50%;
    right: 0;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    background: #fff;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    -webkit-transition: opacity 0.3s, color 0.3s;
    transition: opacity 0.3s, color 0.3s;
  }
  .ant-picker-clear > * {
    vertical-align: top;
  }
  .ant-picker-clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-picker-separator {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    vertical-align: top;
    cursor: default;
  }
  .ant-picker-focused .ant-picker-separator {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
    cursor: not-allowed;
  }
  .ant-picker-range {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .ant-picker-range .ant-picker-clear {
    right: 11px;
  }
  .ant-picker-range:hover .ant-picker-clear {
    opacity: 1;
  }
  .ant-picker-range .ant-picker-active-bar {
    bottom: -1px;
    height: 2px;
    margin-left: 11px;
    background: #1890ff;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    pointer-events: none;
  }
  .ant-picker-range.ant-picker-focused .ant-picker-active-bar {
    opacity: 1;
  }
  .ant-picker-range-separator {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 8px;
    line-height: 1;
  }
  .ant-picker-range.ant-picker-small .ant-picker-clear {
    right: 7px;
  }
  .ant-picker-dropdown {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: absolute;
    z-index: 1050;
  }
  .ant-picker-dropdown-hidden {
    display: none;
  }
  .ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
    top: 1.66666667px;
    display: block;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
    bottom: 1.66666667px;
    display: block;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .ant-picker-dropdown-range {
    padding: 6.66666667px 0;
  }
  .ant-picker-dropdown-range-hidden {
    display: none;
  }
  .ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
    padding-top: 4px;
  }
  .ant-picker-ranges {
    margin-bottom: 0;
    padding: 4px 12px;
    overflow: hidden;
    line-height: 34px;
    text-align: left;
    list-style: none;
  }
  .ant-picker-ranges > li {
    display: inline-block;
  }
  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    cursor: pointer;
  }
  .ant-picker-ranges .ant-picker-ok {
    float: right;
    margin-left: 8px;
  }
  .ant-picker-range-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .ant-picker-range-arrow {
    position: absolute;
    z-index: 1;
    display: none;
    width: 10px;
    height: 10px;
    margin-left: 16.5px;
    -webkit-box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
            box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
  }
  .ant-picker-range-arrow::after {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 10px;
    height: 10px;
    border: 5px solid #f0f0f0;
    border-color: #fff #fff transparent transparent;
    content: '';
  }
  .ant-picker-panel-container {
    overflow: hidden;
    vertical-align: top;
    background: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
            box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    -webkit-transition: margin 0.3s;
    transition: margin 0.3s;
  }
  .ant-picker-panel-container .ant-picker-panels {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    direction: ltr;
  }
  .ant-picker-panel-container .ant-picker-panel {
    vertical-align: top;
    background: transparent;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .ant-picker-panel-container .ant-picker-panel-focused {
    border-color: #f0f0f0;
  }
  .ant-picker-rtl {
    direction: rtl;
  }
  .ant-picker-rtl .ant-picker-suffix {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-picker-rtl .ant-picker-clear {
    right: auto;
    left: 0;
  }
  .ant-picker-rtl .ant-picker-separator {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-picker-rtl.ant-picker-range .ant-picker-clear {
    right: auto;
    left: 11px;
  }
  .ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
    margin-right: 11px;
    margin-left: 0;
  }
  .ant-picker-dropdown-rtl .ant-picker-ranges {
    text-align: right;
  }
  .ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
    float: left;
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-picker-panel-rtl {
    direction: rtl;
  }
  .ant-picker-panel-rtl .ant-picker-prev-icon,
  .ant-picker-panel-rtl .ant-picker-super-prev-icon {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .ant-picker-panel-rtl .ant-picker-next-icon,
  .ant-picker-panel-rtl .ant-picker-super-next-icon {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .ant-picker-cell .ant-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    -webkit-transition: background 0.3s, border 0.3s;
    transition: background 0.3s, border 0.3s;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
    right: 50%;
    left: 0;
  }
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
    right: 0;
    left: 50%;
  }
  .ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
    right: 0;
    left: -7px;
  }
  .ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    right: -7px;
    left: 0;
  }
  .ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
    right: 0;
    left: 50%;
  }
  .ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
    right: 50%;
    left: 0;
  }
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    right: 6px;
    left: 0;
    border-right: 1px dashed #7ec1ff;
    border-left: none;
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    right: 0;
    left: 6px;
    border-right: none;
    border-left: 1px dashed #7ec1ff;
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
  }
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    right: 6px;
    border-right: 1px dashed #7ec1ff;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after {
    left: 6px;
    border-left: 1px dashed #7ec1ff;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .ant-picker-panel-rtl .ant-picker-time-panel {
    direction: ltr;
  }
  
  
  
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .ant-input-affix-wrapper::-moz-placeholder {
    opacity: 1;
  }
  .ant-input-affix-wrapper::-webkit-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input-affix-wrapper:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input-affix-wrapper::-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input-affix-wrapper::placeholder {
    color: #bfbfbf;
  }
  .ant-input-affix-wrapper:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-affix-wrapper:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-input-affix-wrapper:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-affix-wrapper:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-input-affix-wrapper:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-rtl .ant-input-affix-wrapper:focus,
  .ant-input-rtl .ant-input-affix-wrapper-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-affix-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-affix-wrapper-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-affix-wrapper[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-affix-wrapper[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-affix-wrapper-borderless,
  .ant-input-affix-wrapper-borderless:hover,
  .ant-input-affix-wrapper-borderless:focus,
  .ant-input-affix-wrapper-borderless-focused,
  .ant-input-affix-wrapper-borderless-disabled,
  .ant-input-affix-wrapper-borderless[disabled] {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  textarea.ant-input-affix-wrapper {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    -webkit-transition: all 0.3s, height 0s;
    transition: all 0.3s, height 0s;
  }
  .ant-input-affix-wrapper-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-input-affix-wrapper-sm {
    padding: 0px 7px;
  }
  .ant-input-affix-wrapper-rtl {
    direction: rtl;
  }
  .ant-input-affix-wrapper-disabled .ant-input[disabled] {
    background: transparent;
  }
  .ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    border: none;
    outline: none;
  }
  .ant-input-affix-wrapper > input.ant-input:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-input-affix-wrapper::before {
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  .ant-input-prefix,
  .ant-input-suffix {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .ant-input-prefix {
    margin-right: 4px;
  }
  .ant-input-suffix {
    margin-left: 4px;
  }
  .ant-input-clear-icon {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    margin: 0 4px;
    vertical-align: -1px;
  }
  .ant-input-clear-icon:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-input-clear-icon:active {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-input-clear-icon + i {
    margin-left: 6px;
  }
  .ant-input-clear-icon-hidden {
    visibility: hidden;
  }
  .ant-input-clear-icon:last-child {
    margin-right: 0;
  }
  .ant-input-affix-wrapper-textarea-with-clear-btn {
    padding: 0 !important;
    border: 0 !important;
  }
  .ant-input-textarea-clear-icon {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin: 8px 8px 0 0;
  }
  .ant-input-textarea-clear-icon:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-input-textarea-clear-icon:active {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-input-textarea-clear-icon + i {
    margin-left: 6px;
  }
  .ant-input-textarea-clear-icon-hidden {
    visibility: hidden;
  }
  .ant-input {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-input::-moz-placeholder {
    opacity: 1;
  }
  .ant-input::-webkit-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input:-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input::-ms-input-placeholder {
    color: #bfbfbf;
  }
  .ant-input::placeholder {
    color: #bfbfbf;
  }
  .ant-input:-moz-placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input:-ms-input-placeholder {
    text-overflow: ellipsis;
  }
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-rtl .ant-input:focus,
  .ant-input-rtl .ant-input-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-borderless,
  .ant-input-borderless:hover,
  .ant-input-borderless:focus,
  .ant-input-borderless-focused,
  .ant-input-borderless-disabled,
  .ant-input-borderless[disabled] {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  textarea.ant-input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    -webkit-transition: all 0.3s, height 0s;
    transition: all 0.3s, height 0s;
  }
  .ant-input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-input-sm {
    padding: 0px 7px;
  }
  .ant-input-rtl {
    direction: rtl;
  }
  .ant-input-group {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  .ant-input-group[class*='col-'] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-input-group > [class*='col-'] {
    padding-right: 8px;
  }
  .ant-input-group > [class*='col-']:last-child {
    padding-right: 0;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap,
  .ant-input-group > .ant-input {
    display: table-cell;
  }
  .ant-input-group-addon:not(:first-child):not(:last-child),
  .ant-input-group-wrap:not(:first-child):not(:last-child),
  .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle;
  }
  .ant-input-group-wrap > * {
    display: block !important;
  }
  .ant-input-group .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
  }
  .ant-input-group .ant-input:focus {
    z-index: 1;
    border-right-width: 1px;
  }
  .ant-input-group .ant-input:hover {
    z-index: 1;
    border-right-width: 1px;
  }
  .ant-input-group-addon {
    position: relative;
    padding: 0 11px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-input-group-addon .ant-select {
    margin: -5px -11px;
  }
  .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border: 1px solid transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-input-group-addon .ant-select-open .ant-select-selector,
  .ant-input-group-addon .ant-select-focused .ant-select-selector {
    color: #1890ff;
  }
  .ant-input-group-addon > i:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
  .ant-input-group-addon:first-child .ant-select .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group-addon:first-child {
    border-right: 0;
  }
  .ant-input-group-addon:last-child {
    border-left: 0;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
  .ant-input-group-addon:last-child .ant-select .ant-select-selector {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group-lg .ant-input,
  .ant-input-group-lg > .ant-input-group-addon {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-input-group-sm .ant-input,
  .ant-input-group-sm > .ant-input-group-addon {
    padding: 0px 7px;
  }
  .ant-input-group-lg .ant-select-single .ant-select-selector {
    height: 40px;
  }
  .ant-input-group-sm .ant-select-single .ant-select-selector {
    height: 24px;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group.ant-input-group-compact {
    display: block;
  }
  .ant-input-group.ant-input-group-compact::before {
    display: table;
    content: '';
  }
  .ant-input-group.ant-input-group-compact::after {
    display: table;
    clear: both;
    content: '';
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > * {
    display: inline-block;
    float: none;
    vertical-align: top;
    border-radius: 0;
  }
  .ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .ant-input-group.ant-input-group-compact > .ant-picker-range {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: -1px;
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact .ant-input {
    float: none;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
    border-right-width: 1px;
    border-radius: 0;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > .ant-select-focused {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > *:first-child,
  .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .ant-input-group.ant-input-group-compact > *:last-child,
  .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
    border-right-width: 1px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
    vertical-align: top;
  }
  .ant-input-group > .ant-input-rtl:first-child,
  .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-radius: 0 2px 2px 0;
  }
  .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
  }
  .ant-input-group-rtl .ant-input-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #d9d9d9;
  }
  .ant-input-group-rtl .ant-input-group > .ant-input:last-child,
  .ant-input-group-rtl .ant-input-group-addon:last-child {
    border-radius: 2px 0 0 2px;
  }
  .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-radius: 2px 0 0 2px;
  }
  .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 0 2px 2px 0;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: 0;
    margin-left: -1px;
    border-left-width: 1px;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
    border-radius: 0 2px 2px 0;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
    border-left-width: 1px;
    border-radius: 2px 0 0 2px;
  }
  .ant-input-group-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: top;
  }
  .ant-input-password-icon {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-input-password-icon:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-input[type='color'] {
    height: 32px;
  }
  .ant-input[type='color'].ant-input-lg {
    height: 40px;
  }
  .ant-input[type='color'].ant-input-sm {
    height: 24px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .ant-input-search-icon {
    padding: 0 9px;
  }
  .ant-input-search-icon::before {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  .ant-input-search-icon::after {
    width: 32px;
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon {
    padding: 0 12px;
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon::before {
    -webkit-transform: translateX(-13px);
            transform: translateX(-13px);
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon::after {
    width: 40px;
  }
  .ant-input-affix-wrapper-sm .ant-input-search-icon {
    padding: 0 6px;
  }
  .ant-input-affix-wrapper-sm .ant-input-search-icon::before {
    -webkit-transform: translateX(-7px);
            transform: translateX(-7px);
  }
  .ant-input-affix-wrapper-sm .ant-input-search-icon::after {
    width: 24px;
  }
  .ant-input-search-icon {
    margin-left: 0.5em;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-input-search-icon:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-input-search-icon::before {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    border-left: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: '';
  }
  .ant-input-search-icon::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
  }
  .ant-input-search:not(.ant-input-search-enter-button) {
    padding-right: 0;
  }
  .ant-input-search-enter-button input {
    border-right: 0;
  }
  .ant-input-search-enter-button input:hover,
  .ant-input-search-enter-button input:focus {
    border-color: #40a9ff;
  }
  .ant-input-search-enter-button.ant-input-affix-wrapper {
    border-right: 0;
  }
  .ant-input-search-enter-button + .ant-input-group-addon,
  .ant-input-search-enter-button input + .ant-input-group-addon {
    padding: 0;
    border: 0;
  }
  .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
  .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group-wrapper-rtl {
    direction: rtl;
  }
  .ant-input-group-rtl {
    direction: rtl;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
    border: none;
    outline: none;
  }
  .ant-input-affix-wrapper-rtl .ant-input-prefix {
    margin: 0 0 0 4px;
  }
  .ant-input-affix-wrapper-rtl .ant-input-suffix {
    margin: 0 4px 0 0;
  }
  .ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-input-affix-wrapper-rtl .ant-input-textarea-clear-icon {
    right: auto;
    left: 0;
    margin: 8px 0 0 8px;
  }
  .ant-input-search-rtl {
    direction: rtl;
  }
  .ant-input-search-rtl .ant-input-search-icon {
    margin-right: 0.5em;
    margin-left: 0;
  }
  .ant-input-search-rtl .ant-input-search-icon::before {
    border-left: none;
  }
  .ant-input-search-rtl .ant-input-search-icon::after {
    right: auto;
    left: 0;
    border-right: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-input-search-rtl.ant-input-search:not(.ant-input-search-enter-button) {
    padding-right: 11px;
    padding-left: 0;
  }
  .ant-input-search-rtl.ant-input-search-enter-button input {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
  }
  .ant-input-search-rtl.ant-input-search-enter-button input:hover,
  .ant-input-search-rtl.ant-input-search-enter-button input:focus {
    border-color: #40a9ff;
  }
  .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
  }
  .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper:hover,
  .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper:focus {
    border-color: #40a9ff;
  }
  .ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
  .ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
    width: 100%;
    border-radius: 2px 0 0 2px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ant-input {
      height: 32px;
    }
    .ant-input-lg {
      height: 40px;
    }
    .ant-input-sm {
      height: 24px;
    }
    .ant-input-affix-wrapper > input.ant-input {
      height: auto;
    }
}
/*---CronJob End---*/ 
  
/*---Create App Start---*/
.noColor{
  background-color: transparent!important;
} 
/*---Create App End---*/

/*---EnvironmentInfo Start---*/
.square{
  height: 4rem;
  width: 4rem;
  background-color: #0057fa;
}

.appDetailsContainer{
  display: flex;
}

.appDetailsSubContainer{
  margin-left: 1rem;
}

.appName{
  font-weight: bold;
  color : #555;
  font-size: 1.5rem;
}


.tabsContainer{
  margin-top : 1.5rem
}

.horizontalLine{
  border-top : 1px solid lightgray
}

.containerTitles{
  color : #555;
  font-weight: 500;
}
.marginBtn{
  margin:0 auto;
}
.envSubDetails {
  color: #43425D;
  opacity: 0.9;
  margin-top: 0.5rem;
  display:flex;
  font-size: 0.9rem;
  
}
.envSubDetails span{
  display:flex;
  margin-right: 10px;
  align-items: center;;
}

.envTitle{
  padding-top:5px;
}

/* .topinfoGrid{
font-size: 14px;
} */
.topIconStatus{
margin-left:20px;
}
.pluginImg{
  vertical-align: middle;
}

.p-tb-20{
  padding-top:20px;
  padding-bottom:15px;
}
.p-tb-10{
  padding-top:10px;
  padding-bottom:10px;
}
/*---EnvironmentInfo End---*/

/*---Ci Settings Tab Start---*/
.centertxt{
  align-self: center;
}
/*---Ci Settings Tab End---*/

/*---CI Tab Start---*/
  /* Steps */
  .step {
    position: relative;
    min-height: 1em;
    color: gray;
  }
  .step + .step {
    margin-top: 1.5em
  }
  .step > div:first-child {
    position: static;
    height: 0;
  }
  .step > div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
  }
  .step.step-active {
    color: #4285f4
  }
  .step.step-active .circle {
    background-color: #4285f4;
  }
  /* Circle */
  .circle {
    background: gray;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
  }

  /* Vertical Line */
  .circle:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgba(0, 0, 0, 0.25);
  }
  .step:last-child .circle:after {
    display: none
  }

  /* Stepper Titles */
  .title {
    line-height: 1.5em;
    font-weight: bold;
  }
  .caption {
    font-size: 0.8em;
  }
/*---Ci Tab End---*/

/*---Insight Tab Start---*/
.lineChartContainer{
  padding : 1rem 1rem 0.9rem 0.9rem
}

.lineChartTitle{
  color:grey;
  font-size: 0.9rem;
  font-weight:500
}

.graphDropdown{
  min-width: 120px !important;
}

.graphDropdownContainer{
  display: flex;
  justify-content: flex-end;
}
/*---Insight Tab End---*/

/*---Logs Tab Start---*/
.podlogs{
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.arrowgodown{
position: absolute;
top:10px;
right:10px;
}
.arrowgodownbtn{
  background-color: white!important;
}
/*---Logs Tab End---*/

/*---Overview Tab Start---*/
.managedServicesCnameLink{
  color: blue !important;
  cursor: default;
  font-weight: 500;
}
.cnameLink {
  color: blue!important;
}

.overview-key{
  display: inline !important;
  color: "#0000ff";
  margin-right: 2rem;
}
.seeVariables{
  padding-top:12px;
  cursor: pointer;
}
.overviewCard{
  /* min-height:220px; */
  height: 100%;
}
.skeletoncard{
  min-height:200px;
}
.externalUr .MuiIconButton-root {
  padding: 4px;
}
.externalUrllabel{
  margin-left: 2px !important;
}
.externalUr .MuiSvgIcon-fontSizeSmall{
  font-size: 0.9rem!important;
  margin-left: 5px;
  vertical-align: middle;
}
/*---Overview Tab End---*/

/*---Domain Tab Start---*/
.m-10{
  margin-top: 10px!important;
  margin-bottom: 10px!important;
}
.greenIcon{
  color:green;
}
.right{
  float:right;
}
.checkdns{
  align-items: center;
  display: flex;
  
}
.checkdns a{
  color:black!important;
}
.warningIcon{
  color:orange!important;
}
.customCard{
  min-height:100%;
}
.skeletoncard{
  min-height:100%;
}
/*---Domain Tab End---*/

/*---Userpermission Tab Start---*/
.addButtonContainer{
  margin-bottom: 2rem;
}
/*---Userpermission Tab End---*/

/*---Label Handler Start---*/
.labelsvgicon{
  font-size: 18px!important;
  margin-right: 5px;
}

.label-handler-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
}

.env-label-handler-container {
  display: flex;
  align-items: center;
  padding: 2.5px 6px 2.5px 6px;
  border-radius: 8px;
  width: fit-content;
}

.repo-label-handler-container {
  display: flex;
  align-items: center;
  padding: 8px 4px 8px 4px;;
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
}

.label-handler-container .topinfoGrid {
  width: 90%;
}

.label-handler-container .label {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
}
/*---Label Handler End---*/

/*---Date Handler Start---*/
.date-label-container {
  display: flex;
  align-items: center;
  width: 100%;
  /* justify-content: center; */
  padding: 4px;
  border-radius: 8px;
}

.date-label-container .topinfoGrid {
  width: 90%;
}

.date-label-container .label {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*---Date Handler End---*/

/*---Header Start---*/

.breadcrumbs ul {
  list-style: none;
  display: inline-table;
  padding:0;
  margin:0;
}
.breadcrumbs ul li {
  display: inline;
}
.breadcrumbs ul li a {
  display: block;
  float: left;
height:20px;
  background:#357dfd14;
  text-align: center;
  padding: 5px 0px 5px 20px;
  position: relative;
  margin: 0 5px 0 0;

  text-decoration: none;
  color: #444444;
}
.breadcrumbs ul li a:after {
  content: "";
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #357dfd14;
  position: absolute;
  right: -15px;
  top: 0;
  z-index: 1;
}
.breadcrumbs ul li a:before {
  content: "";
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #f8f8f8;
  position: absolute;
  left: 0;
  top: 0;
}
.breadcrumbs ul li:first-child a {
  padding-left:5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

}
.breadcrumbs ul li:first-child a:before {
  display: none;
}
.breadcrumbs ul li:last-child a {
  padding-right: 5px;
  background: #357dfd;
  color: #ffffff;
}
.breadcrumbs ul li:last-child a:after {
  content: "";
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #357dfd;
  position: absolute;
  right: -15px;
  top: 0;

}
.breadcrumbs ul li a:hover {
  background: #357dfd;
  color: #ffffff;
}
.breadcrumbs ul li a:hover:after {
  border-left-color: #357dfd;
  color: #357dfd14;
}
.notificationHeader{
  /* margin-top:20px!important; */
  /* padding-left: 16px;
  padding-right: 16px; */
}
.notificationFooter{
  /* padding-left: 16px;
  padding-right: 16px; */
  background-color: #F5F5F5;
  padding: 6px 10px;
}
/*---Header End---*/

/*---Login Start---*/
.normalText{
	color: grey;
	margin-top : 10px !important;
	margin-bottom: 10px !important;
}

.forgotPasswordContainer{
	margin-top : 13px !important;
	text-align: right;
}

.loginContainer { 
	margin-top: 20% !important;
}
.signup{
	color:#0057FA;
}
.span-or {
	display: block;
	position: absolute;
	left: 50%;
	top: -7px;
	margin-left: -25px;
	background-color: #fff;
	width: 50px;
	text-align: center;
}
.line-or{
	width:100%;
	position:relative;
	
}
.hr-or {
	background:#efefef;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}
.authlogo{
    /* width:100px; */
    /* margin-bottom:20px; */
    display: none;
}
@media screen and (max-width: 1279px) {
	.authlogo{
        display: block;
    }
}

@media screen and (max-width: 955px) {
	.forgotPasswordContainer{
		margin-top : 0px !important;
		text-align: center;
	}
	.rememberContainer{
		text-align: center;
	}
  }

  .login-bg {
	
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ddeff7+0,9fe0f3+50,e1f1f7+100 */
/* background: linear-gradient(to right,  rgb(221,239,247) 0%,rgb(159,224,243) 50%,rgb(225,241,247) 100%);  */

/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ddeff7+0,9fe0f3+50,e1f1f7+100 */
background: linear-gradient(135deg,  rgb(221,239,247) 0%,rgb(50,120,251) 65%,rgb(225,241,247) 100%); 

/* final -> background: linear-gradient(135deg,  rgb(221,239,247) 0%,rgb(50,120,251) 50%,rgb(225,241,247) 100%);  */

/* (0, 87, 250) -> logo colour*/ 
  }

  .radius-30 {
	border-radius: 30px;
  }

/*---Login End---*/

/*---Notification Start---*/
.notificationHeader {
	font-size: 14px;

	color: #555;
	font-weight: 500;
}
.titleHeaderNotification {
	background-color: #f0f1f7 !important;
}
.unread {
	background-color: #F1FFF8 !important;
	font-weight: bold;
  border-radius: 16px;
  margin-bottom: 5px !important;
}
.unreadCard {
	background-color: #F1FFF8 !important;
	font-weight: bold;
  border-radius: 16px;
  /* margin-bottom: 5px !important; */
}
.openLinkIconNotification {
  font-size: 12px !important;
  margin-right: 12px;
  vertical-align: middle;
}
.iconCircleNotification {
	width: 40px;
	height: 40px;
	display: flex;
	overflow: hidden;
	position: relative;
	font-size: 1.25rem;
	align-items: center;
	flex-shrink: 0;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	line-height: 1;
	user-select: none;
	border-radius: 50%;
	justify-content: center;
  background-color: #EDEFF2;
	/* border: 1px solid #d6d5d5; */
}
.notificationcard {
	margin-top: 20px;
}

.read-more-button {
	font-size: 14px;
	text-decoration: underline;
	color: blue;
}

.buttonInActive {
  line-height: 22px;
  font-weight: 500;
  color: #637381 !important;
  font-size: 14px;
  border-radius: 0px !important;
}
.buttonInActive:hover {
  background-color: transparent;
}

.buttonActive {
  line-height: 22px;
  font-weight: 500;
  color: #1C252E;
  font-size: 14px;
  border-radius: 0px !important;
  border-bottom: 2px solid #1C252E !important;
}
.buttonActive:hover {
  background-color: transparent;
}

@media (max-width: 600px) {
	.checkboxGrid {
		display: none;
	}
}
/*---Notification End---*/

/*---Create Organization Start---*/
.orginfo-card{
  min-height: 215px;
}
/*---Create Organization End---*/

/*---Organization Info Start---*/
.tabsContainer {
  margin-top: 0.5rem !important;
}

.topIcon {
  height: 0.8rem !important;
  font-size: 1rem !important;
}
.marginBtn {
  margin: auto;
}
.top-org{
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 180px;
}
@media (max-width: 600px) {
  .top-org {
      max-width: 80px; 
  }
}
/*---Organization Info End---*/

/*---Organization Activity Tab Start---*/
.editCircle {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  border: 0px solid !important;
  background-color: #00B8D929;
}

.errorCircle {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  border: 0px solid !important;
  background-color: #FFE9D5;
}

.org-activities-container{
  background-color: white;
  border-radius: 16px;
}
.org-activities-container .activityMasterContianer {
  margin-bottom: 1rem;
}

.org-activities-container .activityInfoDetailStyle {
  font-size: 0.9rem;
  color: grey;
  font-weight: 500;
  display: block;
}

.org-activities-container .activityInfoLink {
  text-decoration: none;
  color: #00B8D9;
}

.org-activities-container .activityInfoSpan {
  color: black;
}

.org-activities-container .activityInfoUserName {
  margin-left: 1rem;
  font-size: 1rem;
  color: #555;
  font-weight: 600;
}

.org-activities-container .dateStyle {
  font-size: 1rem;
  color: grey;
  font-weight: 500;
}

.org-activities-container .activityMainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.org-activities-container .activityHeader {
  font-size: 14px;
  line-height: 24px;
  color: #637381;
  font-weight: 600;
}
.org-activities-container .titleHeader {
  background-color: #fafafa !important;
}

@media only screen and (max-width: 960px) {
  .org-activities-container .editCircle {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 450px) {
  .org-activities-container .messageStyle {
    text-align: center;
    padding-left: 40px !important;
  }

  .org-activities-container .nameStyle {
    font-size: 13px;
  }

  .org-activities-container .timeStyle {
    /* margin-left : 0.9rem; */
    font-size: 0.7rem;
    color: #555;
    font-weight: 500;
  }
}
/*---Organization Activity tab End---*/

/*---Plugin Start---*/
.search-plugin {
  display: inline !important;
}
.create-plugin-link {
  text-decoration: none;
}

.plugin-search-add {
  margin-bottom: 1.5rem;
}
.w60{
  width:60%;
}
/*---Plugin End---*/

/*---Pod Info Start---*/
.pod-tabs-container {
  display: flex;
}

.pod-tabs-container .pod-info-tabs {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: sticky;
  top: 100px;
}

.pod-tabs-container .container-labels-div {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.pod-tabs-container .container-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pod-info-tabs .tab-label {
  display: flex;
  justify-content: flex-start;
  padding: 10px 15px;
  border-radius: 8px;
  /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2); */
  text-transform: none;
}

.pod-info-tabs .tab-label svg {
  display: none;
}

.pod-info-tabs .tab-label.active {
  justify-content: space-between;
  background-color: #1C252E;
  color: #ffffff;
}

.pod-info-tabs .tab-label.inactive {
  justify-content: space-between;
  background-color: #ffffff;
}

.pod-info-tabs .tab-label.active h5 {
  color: #ffffff;
}

.pod-info-tabs .tab-label.active svg {
  display: block;
}

.pod-info-tabs .pod-tab-label {
  margin-bottom: 10px;
}

.pod-info-tabs .container-tab-label {
  /* width: 90%;
  margin-left: auto; */
}

@media (max-width: 700px) {
  .pod-tabs-container .pod-info-tabs-grid,
  .pod-tabs-container .pod-info-container-grid {
    max-width: 100%;
    flex-basis: 100%;
  }

  .pod-tabs-container .pod-info-tabs {
    overflow-x: auto;
    padding: 5px 0;
  }

  .pod-tabs-container .container-labels-div {
    flex-direction: row;
    column-gap: 10px;
  }

  .pod-info-tabs .tab-label {
    width: 160px;
  }

  .pod-info-container {
    padding: 0;
  }

  .pod-info-tabs .tab-label.active svg {
    transform: rotate(90deg);
  }

  .pod-info-tabs .pod-tab-label {
    margin-bottom: 0;
  }

  .pod-info-tabs .container-tab-label {
    margin-left: 0;
  }
}

@media (max-width: 1400px) and (min-width: 700px) {
  .sidebar-open .pod-tabs-container .pod-info-tabs-grid,
  .sidebar-open .pod-tabs-container .pod-info-container-grid {
    max-width: 100%;
    flex-basis: 100%;
  }

  .sidebar-open .pod-tabs-container .pod-info-tabs {
    overflow-x: auto;
    padding: 5px 0;
  }

  .sidebar-open .pod-tabs-container .container-labels-div {
    flex-direction: row;
    column-gap: 10px;
  }

  .sidebar-open .pod-info-tabs .tab-label {
    width: 160px;
  }

  .sidebar-open .pod-info-container {
    padding: 0;
  }

  .sidebar-open .pod-info-tabs .tab-label.active svg {
    transform: rotate(90deg);
  }

  .sidebar-open .pod-info-tabs .container-tab-label {
    margin-left: 0;
  }

  .sidebar-open .pod-info-tabs .pod-tab-label {
    margin-bottom: 0;
  }
}
/*---Pod Info End---*/

/*---Create Project Start---*/
.projectsMasterContainer{
	padding:2rem;
}

.subscriptionCardsContainer{
	padding:0rem 1rem 1rem 1rem !important;
}

.subscriptionContianer{
	margin-top: 0.5rem !important;
}

.subscribeText{
	font-weight: bold;
}

.oneRemMarginSeperator{
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.uploadIcon{
	margin-bottom: 20px;
}

.inputFile{
	display: none;
}

.projectNameContainer{
	min-height: 40px;
}
.chiplabel{
	font-size: 10px;
    z-index: 16;
    background: white;
    margin-top: -9px;
    position: absolute;
    padding: 4px;
    margin-left: 9px;
}
/*---Create Project End---*/

/*---Project Info Start---*/
.square{
  height: 3rem!important;
  width: 3rem!important;
  background-color: #f6f8fa !important;
  border : 1px solid lightgray
}

.projectIcon{
  width : inherit !important;
  height : inherit !important;
}
.projecticonImg{
  object-fit: cover;
}
.projectDetailsContainer{
  display: flex;
}

.projectDetailsSubContainer{
  margin-left: 1rem;
  margin-top:0.5rem;
  width: 100%;
}

.projectName{
  font-weight: 800;
  color : rgb(52, 52, 61);
  font-size: 1.05rem;
}

.projectSubDetails{
  color: #43425D;
  opacity: 0.9;
  font-weight: 400;
  font-size: 0.9rem;
}

.tabsContainer{
  margin-top : 0.5rem!important;
}

/* .MuiTab-textColorInherit.Mui-selected{
  color : #0057fa !important;
} */

.PrivateTabIndicator-colorSecondary-167{
  background-color : #0057fa !important;
}

.horizontalLine{
  border-top : 1px solid lightgray
}

.containerTitles{
  color : #555;
  font-weight: 500;
}
.topIcon{
height:0.8rem!important;
font-size: 1rem!important;
}
.marginBtn{
  margin:auto;
}
/*---Project Info End---*/

/*---Project Info Activity Tab Start---*/


.activityMasterContianer{
  margin-bottom: 1rem;
}

.activityInfoDetailStyle{
  font-size: 0.9rem;
  color: grey;
  font-weight: 500;
  display: block;
}

.activityInfoLink{
  text-decoration: none;
  color : #0057fa;
}

.activityInfoSpan{
  color : black;
}

.activityInfoUserName{
  margin-left : 1rem;
  font-size: 1rem;
  color: #555;
  font-weight: 600;
}

.dateStyle{
  font-size: 1rem;
  color: grey;
  font-weight: 500;
}

.activityMainContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.activityHeader{
  font-size: 14px;
  color: #555;
  font-weight: 500;
}
.titleHeader{
  background-color: #fafafa!important;
}

.iconCircle{
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;   
  background-color: #EDEFF2;
	/* border: 1px solid #d6d5d5; */
}

@media only screen and (max-width: 960px) {
  .iconCircle {
      width: 30px;
      height: 30px;
  }
}

@media only screen and (max-width: 450px) {
  .messageStyle {
      text-align: center;
      padding-left: 40px !important;
  }

  .nameStyle {
      font-size: 13px;
  }

  .timeStyle{
      /* margin-left : 0.9rem; */
      font-size: 0.7rem;
      color: #555;
      font-weight: 500;
  }
}
/*---Project Info Activity Tab End---*/

/*---Project Info Dashboard Tab Start---*/
.rightbtn{
  float:right;
}

.appsTitle{
  display: inline;
}
.usersTitle{
  margin-top:2rem!important;
  margin-bottom:1.5rem!important;
}

.refreshIcon{
  padding-top: 5px !important;
  padding-right: 1rem !important;
}
/*---Project Info Dashboard Tab End---*/

/*---Project Info Insight Tab Start---*/
.lineChartContainer{
  padding : 1rem 1rem 0.9rem 0.9rem
}

.lineChartTitle{
  color:grey;
  font-size: 0.9rem;
  font-weight:500
}

.graphDropdown{
  min-width: 120px !important;
}

.graphDropdownContainer{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
/*---Project Info Insight Tab End---*/

/*---Project Info Settings Tab Project Start---*/
.deleteProjectButton{
  margin-right : 0.5rem !important
}

.editProjectButton{
  margin-left : 0.5rem !important
}
.edit-icon-align {
  vertical-align: top;
  margin-left : 0.5rem !important;
  cursor: pointer;
}
.upload-icon-align{
  vertical-align: middle;
  margin:5px;
  cursor: pointer;
}
.dark h2{
  color:#444!important;
}
.MuiDialog-paper {
  min-width: 360px;
}

.imgWrapper{
  width: 120px;
  height: 120px;
  border-radius: .42rem;
  background-repeat: no-repeat;
  background-size: cover;
  border:2px solid #f8f8f8;
}
.imgContainer{
  display: flex;
  justify-content: center;
}
.circlebtn{
  border: 2px solid #f8f8f8;
  background-color: white;
  position:absolute;
  right:-5px;
  height:30px;
  width:30px;
  border-radius:50%;
  top:-10px;
}

.projectIconCard{
  min-height:290px;
}

.projectInfoCard{
  min-height:420px;
}
/*---Project Info Settings Tab Project End---*/

/*---Project Info Subscription Tab Start---*/
.currentSubscriptionInfo{
  color : #555;
  font-size: 0.8rem;
}
/*---Project Info Subscription Tab End---*/

/*---UserPermission Start---*/
.addButtonContainer{
  margin-bottom: 2rem;
}
/*---UserPermission End---*/

/*---Project List Start---*/
.projectListContainer { 
	display : flex;
	justify-content: space-between;
	margin : 20px 20px 20px 20px;
	align-items:center;	
}

.welcomtextContainer { 
	display : flex;
	justify-content: flex-end;
	margin : 20px 20px 20px 20px;
	align-items:center;	
}

.projectTitlesContainer{
	flex: 1;
}
.hideTrailingChar{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.allProjectListsContainer{
	margin : 20px 20px 20px 20px;
}

.containerTitle{
	font-weight: bold;
	color: grey;
}

.defaultImg{
	width:400px;
	object-fit: cover;
	max-width: 100%;
}
.imgGrid{
	text-align: center;
}

.nameSkeleton{
	display: flex;
	justify-content:center;
	align-items: center;
}
/*---Project List End---*/

/*---Registration Start---*/
.registrationFormHeading {
	color : lightgrey !important;
}

.registrationInputField {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

.registrationFormContainer { 
	margin-top: 10%;
	margin-left: 20%;
	margin-right: 20%;
 }

 .registrationFormNamesContainer {
 	margin-top: 5px !important;
 	margin-bottom: 1px !important;
 }

 .signInLink {
 	/* color: #B7B9CB  !important; */
 	display: block  !important;
	margin-top: 10px !important;
	text-decoration: none  !important;
 }

 .MuiFormControlLabel-label{
 	color: grey;
 	margin-top: 10px !important;
 	margin-bottom: 10px !important;
 }

 .TermsLink{
 	color: grey !important;
 	margin-right: 20px !important;
 }

 /* .MuiTypography-colorPrimary {
 	color : grey !important;
 } */

 .signInButton {
 	margin-top: 10px !important;
 	margin-bottom: 10px !important;
 }

 .containerDiv { 
 	display: flex;
 	justify-content: space-between;
 }

 .signInDiv{
 	display: flex;
	text-align: center;
	margin-top:0.5rem;
 }
 .resetButtonDiv{
	display: flex;
   text-align: center;
}

 .signInButtonDiv{
	 margin-top: 1rem;
	 /* margin-bottom: 1rem; */
 	display: flex;
 	/* justify-content: center; */
 }
.forgotpasswordButtonDiv{
	margin-top: 1rem;
	display: flex;
	justify-content: center;
}
 .registrationButtonDiv{
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	display: flex;
	justify-content: center;
 }

 .mainContainer{
 	height: 100vh !important;
 }

 .lastNameInputBox{
	 margin-left: 0.5rem !important;
 }

 .firstNameInputBox{
	 margin-right: 0.5rem !important;
 }

 .tnscheck label{
	 margin-left:auto;
	 margin-right:auto;
 }

 @media screen and (min-width: 1280px) {
	.containerDiv { 
		display: flex;
		justify-content: space-between;
	}
  }
/*---Registration End---*/

/*---Sidenavbar Start---*/
.newProjectTitle{
	color: white;
	text-decoration: none;
	font-size: 12px
}

.newProjectContainer{
	display: flex;
	justify-content: center;
}

.MuiDrawer-paperAnchorDockedLeft{
	border-right: 0px !important;
}

.linkText{
	color : 'white'
}
.sidebarProjects{
	width:60px;
	flex-direction: column!important;
	display: flex;
	background: #0057fa;
}

.sidebarMain{
	display: flex;
	position: fixed;
	left:0;
	right:auto;
	width:300px;
	top:0;
	height:100vh;
}

.sidebarMainMini{
	display: flex;
	position: fixed;
	left:0;
	right:auto;
	/* width:300px; */
	top:0;
	height:100%;
}

.sidebarProjectsTop{
	align-items: center!important;
	flex-direction: column!important;
	display: flex!important;
	box-sizing: border-box;
	text-align: center;
}
.sidebatProjectsBottom{
	align-items: center!important;
	flex-direction: column!important;
	display: flex!important;
	box-sizing: border-box;
}
.sidebatProjectsMiddle{
	align-items: center!important;
	flex-direction: column!important;
	display: flex!important;
	box-sizing: border-box;
}
.projectTitleDiv{
	padding:10px;
}
.organizationDiv{
    min-height: 44px;
    align-items: center;
    padding: 10px 10px 10px 25px;
    /* background: #0057fa; */
    display: flex;
	
}

@media (max-width: 600px) {
  .organizationDiv {
    padding: 10px 10px 10px 10px;
  }
}

.txt-white{
	color:white!important;
}
.sidebarProjectMiddle{
height:90%;
}
.expansionPanelSummary .MuiExpansionPanelSummary-content{
	margin:0!important;
}
.expansionPanelSummary {
	min-height:24px!important;
}
.expansionDetails{

	padding: 0 0 24px 44px!important;
}
.listenv{
	padding-top:0px!important;	
	width:100%;
}
.listenv li button{
	width: 100%;
	justify-content: start!important;
}
.expansionPanel{
	margin-top:0!important;
}
.noenv{
	padding: 0 24px 0 24px;
}

.noapps{
	padding: 24px 24px 0 24px;
}
.sidebarApps{
	max-height: calc(100vh - 9rem);
	overflow-y: auto;
}

.sidebarApps::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px #f8f8f8;
	background-color: #424242;
  }
  
  .sidebarApps::-webkit-scrollbar {
	width: 5px;
	background-color: #f5f5f5;
  }
  
  .sidebarApps::-webkit-scrollbar-thumb {
	background-color: #f5f5f5;
	border: 1px solid #f5f5f5;
  }
  /*---Sidenavbar End---*/

  /***Components Start***/
  
  /*---Activity Logs Start---*/
  .logsContainer {
    background-color: #424242!important;
    color: white;
    margin-top: 20;
    height: 40vh;
    white-space: pre-line;
    overflow-y: auto;
  }
  
  .normal-log th{
  color:#8ca909!important;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
  font-weight:100;
  padding-top:0;
  padding-bottom:0;
  }
  
  .warning-log th{
  color:orange!important;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
  font-weight: 100;
  padding-top:0;
  padding-bottom:0;
  }
  .error-log th{
  color:#D04647!important;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
  padding-top:0;
  padding-bottom:0;
  }
 /*---Activity Logs End---*/

 /*---Add on Card Start---*/
 .openLinkIcon {
  font-size: 0.9rem !important;
  margin-left: 5px;
  vertical-align: middle;
}
/*---Add on Card End---*/

/*---Addons Popup Start---*/
.imageContainer{
  height: 120px;
  width: 120px;
  margin: 0 auto;
  /* border: 1px solid #eeeeee; */
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addonimage{
  width: 120px;
  height: 120px;
  background-repeat: no-repeat !important;
  background-color: white;
  background-size: cover !important;
  background-position: center !important;
}

.loader-center{
  align-content: center;
  text-align: center;
  margin: 1rem;
}
/*---Addons Popup End---*/

/*---Auto Complete Start---*/
.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #f1f1f1;
  color: #1e1e1e;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
/*---Auto Complete End---*/

/*---CI CD Card Start---*/
.step {
  /* margin-top: 15px;
  margin-left: 8%; */
  position: relative;
  min-height: 3em;
  color: gray;
}
.step + .step {
  margin-top: 2.5em
}
.step > div:first-child {
  position: static;
  height: 0;
}
.step > div:not(:first-child) {
  margin-left: 2.5em;
  padding-left: 1em;
}
.step.step-active {
  color: #4285f4
}
.step.step-active .circle {
  background-color: #4285f4;
}
.iconSizeWidth {
  width: 24px;
}
.clusterStep{
  position: relative;
  min-height: 3em;
  color: gray;
}


/* Circle */
.circle {
  position: relative;
}

.circle:after {
  content: ' ';
  position: absolute;
  display: block;
  top: -7px;
  bottom: 1px;
  left: 48%;
  height: 100%;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.step:last-child .circle:after {
  display: none
}
.time{
  margin-right:20px;
}

/* Stepper Titles */
.title {
  line-height: 1.5em;
  
  display: inline-block;
}
.commitmsg{
  margin-top: 5px;
  font-size: 18px;
  color: #357dfd;
  margin-bottom: 5px;
  font-weight: 500;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 8px;
  border: 2px solid white;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: white transparent transparent transparent;
}
.running div{
  border: 2px solid #f5c60b;
  border-color: #f5c60b transparent transparent transparent;

}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds1-ring {
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin-left: -22px;
  margin-top: -2px;
}

.lds1-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  margin: 6px;
  border: 1.5px solid white;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: white transparent transparent transparent;
}

.lds1-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds1-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds1-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds1-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.center{
  text-align: center;
}

.rightalign{
  text-align: right;
}

.stick-right {
  margin-left: auto !important;
}

.successStep{
  color:#198038;
}

.runningStep{
  color:#f5c60b;
}

.pendingStep{
  color: rgb(196, 191, 191);
}

.failedStep {
  color: #f30f0f;
}

.sucessIcon{
  color:#53b43f;
  height:1.5em;
}
.green{
  background:#53b43f;
}

.red{
  background:#f30f0f;
}

.orange{
  background:#f5c60b;
}

.header-details-grid {
  display: flex;
  align-items: center;
}

.header-details-grid .MuiIconButton-root{
  padding: 0px !important;
}

.stepsRight{
  display: inline-flex;
  float:right;
  margin-right: 4%
}

.logsIcon{
  width:0.8em!important;
  height:0.8em!important;
  cursor: pointer;
}

.pendingStepIcon{
  color: white;
  font-size: 15px!important;
}

.lds1-hour {
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin-left: -20px;
  top:4px;
}
.infoGrid{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;

}

@media (max-width: 500px) {
  .stick-right {
    margin-right: auto !important;
  }
}
/*--- CI CD Card End---*/

/*---CI CD Popup Start---*/
.inner{

}
.logstxt {
  color: white !important;
  white-space: pre-line !important;
}

.logs-container {
  max-height: 400px;
  width: "90%";
  overflow-x: auto;
  display: flex;
  flex-direction: column-reverse;
}

.logs-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #424242;
}

.logs-container::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.logs-container::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}
.cicdPre{
    white-space: pre-wrap;       
    white-space: -moz-pre-wrap; 
    white-space: -pre-wrap;    
    white-space: -o-pre-wrap;
    word-wrap: break-word;     
}
/*---CI CD Popup End---*/

/*---Company Banner Start---*/
.image{
  background-image: url(/images/infographics/login.svg) !important;
  background-repeat : no-repeat !important;
  background-color : rgb(0,0,0,72) !important;
  background-size: cover !important;
  background-position: center !important;
}

/* <style> */
  .relative {
    position: relative;
  }

  .p-0 {
    padding: 0;
  }

  .m-0 {
    margin: 0;
  }

  .cardHeaderpading{
    padding: 24px !important;
  }

  .cardpading{
    padding: 24px 20px !important;
  }

  .cardActionpadding{
    padding: 0px 20px 20px 20px !important;
  }


  .elementor *,
  .elementor :after,
  .elementor :before {
    box-sizing: border-box;
  }


  .elementor-element,
  .elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
  }

  .elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self);
  }

  .e-con-full.e-flex,
  .e-con.e-flex>.e-con-inner {
    flex-direction: var(--flex-direction);
  }

  .e-con,
  .e-con>.e-con-inner {
    display: var(--display);
  }

  .e-con.e-flex {
    --flex-direction: column;
    --flex-basis: auto;
    --flex-grow: 0;
    --flex-shrink: 1;
    flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis);
  }

  .e-con-full,
  .e-con>.e-con-inner {
    text-align: var(--text-align);
    padding-block-start: var(--padding-block-start);
    padding-block-end: var(--padding-block-end);
  }

  .e-con {
    --border-radius: 0;
    --border-top-width: 0px;
    --border-right-width: 0px;
    --border-bottom-width: 0px;
    --border-left-width: 0px;
    --border-style: initial;
    --border-color: initial;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --content-width: min(100%, var(--container-max-width, 1140px));
    --width: 100%;
    --min-height: initial;
    --height: auto;
    --text-align: initial;
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
    --padding-top: var(--container-default-padding-top, 10px);
    --padding-right: var(--container-default-padding-right, 10px);
    --padding-bottom: var(--container-default-padding-bottom, 10px);
    --padding-left: var(--container-default-padding-left, 10px);
    --position: relative;
    --z-index: revert;
    --overflow: visible;
    --gap: var(--widgets-spacing, 20px);
    --overlay-mix-blend-mode: initial;
    --overlay-opacity: 1;
    --overlay-transition: 0.3s;
    --e-con-grid-template-columns: repeat(3, 1fr);
    --e-con-grid-template-rows: repeat(2, 1fr);
    position: var(--position);
    width: var(--width);
    min-width: 0;
    min-height: var(--min-height);
    height: var(--height);
    border-radius: var(--border-radius);
    z-index: var(--z-index);
    overflow: var(--overflow);
    transition: background var(--background-transition, .3s), border var(--border-transition, .3s), box-shadow var(--border-transition, .3s), transform var(--e-con-transform-transition-duration, .4s);
    margin-block-start: var(--margin-block-start);
    margin-inline-end: var(--margin-inline-end);
    margin-block-end: var(--margin-block-end);
    margin-inline-start: var(--margin-inline-start);
    padding-inline-start: var(--padding-inline-start);
    padding-inline-end: var(--padding-inline-end);
    --margin-block-start: var(--margin-top);
    --margin-block-end: var(--margin-bottom);
    --margin-inline-start: var(--margin-left);
    --margin-inline-end: var(--margin-right);
    --padding-inline-start: var(--padding-left);
    --padding-inline-end: var(--padding-right);
    --padding-block-start: var(--padding-top);
    --padding-block-end: var(--padding-bottom);
    --border-block-start-width: var(--border-top-width);
    --border-block-end-width: var(--border-bottom-width);
    --border-inline-start-width: var(--border-left-width);
    --border-inline-end-width: var(--border-right-width);
  }

  .elementor-element:where(.e-con-full, .elementor-widget) {
    flex-direction: var(--flex-direction);
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    gap: var(--gap);
  }

  .e-con:before {
    transition: background var(--overlay-transition, .3s), border-radius var(--border-transition, .3s), opacity var(--overlay-transition, .3s);
  }

  .e-con:before,
  .e-con>.elementor-background-slideshow:before,
  .e-con>.elementor-motion-effects-container>.elementor-motion-effects-layer:before,
  :is(.e-con, .e-con>.e-con-inner)>.elementor-background-video-container:before {
    content: var(--background-overlay);
    display: block;
    position: absolute;
    mix-blend-mode: var(--overlay-mix-blend-mode);
    opacity: var(--overlay-opacity);
    transition: var(--overlay-transition, .3s);
    border-radius: var(--border-radius);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-block-start-width: var(--border-block-start-width);
    border-inline-end-width: var(--border-inline-end-width);
    border-block-end-width: var(--border-block-end-width);
    border-inline-start-width: var(--border-inline-start-width);
    top: calc(0px - var(--border-top-width));
    left: calc(0px - var(--border-left-width));
    width: max(100% + var(--border-left-width) + var(--border-right-width), 100%);
    height: max(100% + var(--border-top-width) + var(--border-bottom-width), 100%);
  }

  /* :root {
    --page-title-display: block;
  } */
/* </style>
<style id='elementor-frontend-inline-css'> */
  .elementor-kit-14 {
    --e-global-color-primary: #885EFE;
    --e-global-color-secondary: #54595F;
    --e-global-color-text: #7A7A7A;
    --e-global-color-accent: #61CE70;
    --e-global-color-dcd2fa1: #885EFE;
    --e-global-color-b0f5881: #5B49F5;
    --e-global-typography-primary-font-family: "Golos Text";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-text-font-family: "Golos Text";
    --e-global-typography-text-font-size: 17px;
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-text-line-height: 1.41em;
    --e-global-typography-d569705-font-family: "JetBrains Mono";
    --e-global-typography-d569705-font-size: 12px;
    --e-global-typography-d569705-font-weight: 700;
    --e-global-typography-d569705-text-transform: uppercase;
    --e-global-typography-d569705-letter-spacing: 1px;
    --e-global-typography-68baa2e-font-weight: 500;
    /* background-color: #FFFFFF; */
    --lqd-body-font-family: var(--e-global-typography-text-font-family), Sans-serif;
    --lqd-body-font-size: var(--e-global-typography-text-font-size);
    --lqd-body-font-weight: var(--e-global-typography-text-font-weight);
    --lqd-body-line-height: var(--e-global-typography-text-line-height);
    --lqd-body-text-color: #0000008C;
    --lqd-heading-font-family: "Golos Text", Sans-serif;
    --lqd-heading-color: #000000;
    --lqd-h2-font-size: 46px;
    --lqd-h2-font-weight: 500;
    --lqd-h3-font-size: 36px;
    --lqd-h3-font-weight: 500;
    --lqd-h6-font-family: "JetBrains Mono", Sans-serif;
    --lqd-h6-font-size: 12px;
    --lqd-h6-text-transform: uppercase;
    --lqd-h6-line-height: 1em;
    --lqd-h6-letter-spacing: 1px;
    --lqd-btn-pt: 24px;
    --lqd-btn-pe: 36px;
    --lqd-btn-pb: 24px;
    --lqd-btn-ps: 36px;
    --lqd-btn-color: #FFFFFF;
    --lqd-btn-bg: var(--e-global-color-b0f5881);
    --lqd-btn-brrt: 14px;
    --lqd-btn-brre: 14px;
    --lqd-btn-brrb: 14px;
    --lqd-btn-brrs: 14px;
  }

  @media(max-width:1024px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
      max-width: 1024px;
    }

    .e-con {
      --container-max-width: 1024px;
    }

    .elementor-kit-14 {
      --lqd-body-font-size: var(--e-global-typography-text-font-size);
      --lqd-body-line-height: var(--e-global-typography-text-line-height);
    }
  }

  @media(max-width:767px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
      max-width: 767px;
    }

    .e-con {
      --container-max-width: 767px;
    }

    .elementor-kit-14 {
      --lqd-body-font-size: var(--e-global-typography-text-font-size);
      --lqd-body-line-height: var(--e-global-typography-text-line-height);
      --lqd-h2-font-size: 10vw;
    }
  }

  .elementor-1430 .elementor-element.elementor-element-113a269 {
    margin-top: 24px;
    margin-bottom: 24px;
    --display: flex;
    /* --min-height: 100vh; */
    --flex-direction: column;
    --container-widget-width: calc((1 - var(--container-widget-flex-grow)) * 100%);
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --justify-content: center;
    --align-items: center;
    --background-transition: 0.3s;
    --padding-top: 0px;
    --padding-bottom: 0px;
    --padding-left: 0px;
    --padding-right: 0px;
    --z-index: 1;
  }

  .elementor-1430 .elementor-element.elementor-element-8bc3715 {
    --display: flex;
    --align-items: center;
    --container-widget-width: calc((1 - var(--container-widget-flex-grow)) * 100%);
    --gap: 30px 30px;
    --background-transition: 0.3s;
  }

  .elementor-1430 .elementor-element.elementor-element-a0e3fcc .elementor-repeater-item-dac949e {
    margin: 0px 13px 0px 0px;
  }

  .elementor-1430 .elementor-element.elementor-element-a0e3fcc .elementor-repeater-item-dac949e img {
    vertical-align: middle;
  }

  .elementor-1430 .elementor-element.elementor-element-a0e3fcc .elementor-repeater-item-5cdb239.lqd-text-item {
    background: linear-gradient(90deg, #8D65E9 0%, #5391E4 47.61904761904761%, #6BCD94 100%);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    text-fill-color: transparent !important;
  }

  .elementor-1430 .elementor-element.elementor-element-a0e3fcc .lqd-text-el {
    font-size: 14px;
  }

  .elementor-1430 .elementor-element.elementor-element-9ce76f6 .elementor-repeater-item-dd28e1d.lqd-text-item {
    background: linear-gradient(90deg, #8D65E9 0%, #5391E4 47.61904761904761%, #6BCD94 100%);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    text-fill-color: transparent !important;
  }

  .elementor-1430 .elementor-element.elementor-element-9ce76f6 .elementor-repeater-item-19905a5.lqd-text-item {
    background: linear-gradient(180deg, #000000 10.95%, #00000000 85.24%);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    text-fill-color: transparent !important;
  }

  .elementor-1430 .elementor-element.elementor-element-9ce76f6 .lqd-text-el {
    font-size: 95px;
    font-weight: bold;
    line-height: 0.8em;
    letter-spacing: -5px;
  }

  .elementor-1430 .elementor-element.elementor-element-9ce76f6 {
    text-align: center;
  }

  .elementor-1430 .elementor-element.elementor-element-9f0d554 {
    text-align: center;
    width: var(--container-widget-width, 52.796%);
    max-width: 52.796%;
    --container-widget-width: 52.796%;
    --container-widget-flex-grow: 0;
  }

  @media(min-width:768px) {
    .elementor-1430 .elementor-element.elementor-element-8bc3715 {
      --width: 688px;
    }

    /* 
      //.elementor-1430 .elementor-element.elementor-element-21cd517 {
      //  --width: 1100px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4a44d31 {
      //  --width: 1100px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-02223c9 {
      //  --width: 1100px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-22e49a8 {
      //  --width: 270.391px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-fa1d0aa {
      //  --width: 71.943%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-21ad33f5 {
      //  --width: 62px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-1366076c {
      //  --width: 62px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-752a8f4a {
      //  --width: 62px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-26ce90b4 {
      //  --width: 62px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-2fdff4c1 {
      //  --width: 62px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-662944d6 {
      //  --width: 62px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-422c2f77 {
      //  --width: 62px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-35c3f41 {
      //  --width: 1100px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-117c0e6b {
      //  --content-width: 520px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-311eade4 {
      //  --content-width: 680px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-69783964 {
      //  --width: 1100px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-59f19009 {
      //  --width: 1100px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-575c7dda {
      //  --width: 516px;
      //} */
  }

  @media(max-width:1024px) and (min-width:768px) {
    /* //.elementor-1430 .elementor-element.elementor-element-0ebe55c {
      //  --width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-868682a {
      //  --width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-21cd517 {
      //  --width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4a44d31 {
      //  --width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-02223c9 {
      //  --width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-69783964 {
      //  --width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-59f19009 {
      //  --width: 100%;
      //} */
  }

  @media(max-width:1024px) {
    .elementor-1430 .elementor-element.elementor-element-9f0d554 .lqd-text-el {
      font-size: var(--e-global-typography-68baa2e-font-size);
      line-height: var(--e-global-typography-68baa2e-line-height);
      letter-spacing: var(--e-global-typography-68baa2e-letter-spacing);
      word-spacing: var(--e-global-typography-68baa2e-word-spacing);
    }

    .elementor-1430 .elementor-element.elementor-element-045d361 {
      --flex-direction: column;
      --container-widget-width: 100%;
      --container-widget-height: initial;
      --container-widget-flex-grow: 0;
      --container-widget-align-self: initial;
      --gap: 40px 40px;
      --padding-top: 10px;
      --padding-bottom: 10px;
      --padding-left: 10px;
      --padding-right: 10px;
    }

    /* //.elementor-1430 .elementor-element.elementor-element-220d705 {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-519621e>.elementor-widget-container {
      //  padding: 0em 0em 0em 0em;
      //}

      //.elementor-1430 .elementor-element.elementor-element-868682a {
      //  --justify-content: space-between;
      //}

      //.elementor-1430 .elementor-element.elementor-element-2b0ae53 {
      //  --padding-top: 20px;
      //  --padding-bottom: 20px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-21cd517 {
      //  --flex-direction: column;
      //  --container-widget-width: 100%;
      //  --container-widget-height: initial;
      //  --container-widget-flex-grow: 0;
      //  --container-widget-align-self: initial;
      //}

      //.elementor-1430 .elementor-element.elementor-element-504a1aa {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-e8d8a52 {
      // --flex-wrap: wrap;
      //  --margin-top: 0px;
      //  --margin-bottom: 0px;
      //  --margin-left: 0px;
      //  --margin-right: 0px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-465dac1>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-999ed0e>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-40ac343>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-da62467 {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-2ac1650 {
      //  --flex-wrap: wrap;
      //  --margin-top: 0px;
      //  --margin-bottom: 0px;
      //  --margin-left: 0px;
      //  --margin-right: 0px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-cd0678d>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4ddc995>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4fac7f9>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4a44d31 {
      //  --flex-direction: column;
      //  --container-widget-width: 100%;
      //  --container-widget-height: initial;
      //  --container-widget-flex-grow: 0;
      //  --container-widget-align-self: initial;
      //}

      //.elementor-1430 .elementor-element.elementor-element-ac0c3bf {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-0f7d791 {
      //  --flex-wrap: wrap;
      //  --margin-top: 0px;
      //  --margin-bottom: 0px;
      //  --margin-left: 0px;
      //  --margin-right: 0px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-c58a4fe>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4628c51>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-034db70>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-02a5687 {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-e34e25a {
      //  --flex-wrap: wrap;
      //  --margin-top: 0px;
      //  --margin-bottom: 0px;
      //  --margin-left: 0px;
      //  --margin-right: 0px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-21ddb98>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-35d04de>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-f3f8adc>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-f19cdde>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-c1a5d01>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-1e1af88>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-829fa40>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-d608449>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-74cd024>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-7a5983b>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-9aa8315>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-179fe94c {
      //  --padding-top: 120px;
      //  --padding-bottom: 80px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-3d15eeef {
      //  --padding-top: 0px;
      //  --padding-bottom: 80px;
      //  --padding-left: 80px;
      //  --padding-right: 80px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-1570d8c {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-8d5e31f {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-3b08a59d {
      //  --padding-top: 20px;
      //  --padding-bottom: 20px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-26d7af46 {
      //  --padding-top: 20px;
      //  --padding-bottom: 20px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //} */
  }

  @media(max-width:767px) {
    .elementor-1430 .elementor-element.elementor-element-9ce76f6 .lqd-text-el {
      font-size: 14vw;
      letter-spacing: -2px;
    }

    .elementor-1430 .elementor-element.elementor-element-9f0d554 .lqd-text-el {
      font-size: var(--e-global-typography-68baa2e-font-size);
      line-height: var(--e-global-typography-68baa2e-line-height);
      letter-spacing: var(--e-global-typography-68baa2e-letter-spacing);
      word-spacing: var(--e-global-typography-68baa2e-word-spacing);
    }

    .elementor-1430 .elementor-element.elementor-element-9f0d554 {
      --container-widget-width: 100%;
      --container-widget-flex-grow: 0;
      width: var(--container-widget-width, 100%);
      max-width: 100%;
    }

    /* //.elementor-1430 .elementor-element.elementor-element-868682a {
      //  --justify-content: space-between;
      //  --flex-wrap: wrap;
      //}

      //.elementor-1430 .elementor-element.elementor-element-169a2b8 {
      //  --container-widget-width: 100%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 100%);
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-b16e6a4 {
      //  --container-widget-width: 100%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 100%);
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-fdfc32b {
      //  --container-widget-width: 100%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 100%);
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-d0af333 {
      //  --container-widget-width: 100%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 100%);
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-22dcd81 {
      //  --container-widget-width: 100%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 100%);
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-e0e58e2 {
      //  --container-widget-width: 100%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 100%);
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-e3fb124 {
      //  --margin-top: 60px;
      //  --margin-bottom: 0px;
      //  --margin-left: 0px;
      //  --margin-right: 0px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-6153637 {
      //  --padding-top: 40px;
      //  --padding-bottom: 90px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-504a1aa {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-465dac1>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-465dac1 {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-999ed0e>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-999ed0e {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-40ac343>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-40ac343 {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-a8d4693 {
      //  --padding-top: 40px;
      //  --padding-bottom: 90px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-da62467 {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-cd0678d>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-cd0678d {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4ddc995>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4ddc995 {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4fac7f9>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4fac7f9 {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-22b672d {
      //  --padding-top: 40px;
      //  --padding-bottom: 90px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-ac0c3bf {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-c58a4fe>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-c58a4fe {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4628c51>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4628c51 {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-034db70>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-034db70 {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-688b265 {
      //  --padding-top: 40px;
      //  --padding-bottom: 90px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-02a5687 {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-a29a67e {
      //  text-align: left;
      //}

      //.elementor-1430 .elementor-element.elementor-element-369acf2 {
      //  text-align: justify;
      //}

      //.elementor-1430 .elementor-element.elementor-element-21ddb98>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-21ddb98 {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-35d04de>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-35d04de {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-f3f8adc>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-f3f8adc {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-02223c9 {
      //  --align-items: center;
      //  --container-widget-width: calc((1 - var(--container-widget-flex-grow)) * 100%);
      //  --padding-top: 20px;
      //  --padding-bottom: 20px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-102f4ca {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-cb04a91 {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-0617e1a {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-fa1d0aa {
      //  --padding-top: 50px;
      //  --padding-bottom: 50px;
      //  --padding-left: 30px;
      //  --padding-right: 30px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-3a63c68 {
      //  --flex-direction: row;
      //  --container-widget-width: calc((1 - var(--container-widget-flex-grow)) * 100%);
      //  --container-widget-height: 100%;
      //  --container-widget-flex-grow: 1;
      //  --container-widget-align-self: stretch;
      //  --justify-content: center;
      //  --align-items: flex-start;
      //  --flex-wrap: wrap;
      //}

      //.elementor-1430 .elementor-element.elementor-element-f19cdde>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-f19cdde {
      //  --container-widget-width: 50%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 50%);
      //  max-width: 50%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-f19cdde.elementor-element {
      //  --order: -99999
      
      //  ;
      //}

      //.elementor-1430 .elementor-element.elementor-element-c1a5d01>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-c1a5d01 {
      //  --container-widget-width: 50%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 50%);
      //  max-width: 50%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-1e1af88>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-1e1af88 {
      //  --container-widget-width: 50%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 50%);
      //  max-width: 50%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-829fa40>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-829fa40 {
      //  --container-widget-width: 50%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 50%);
      //  max-width: 50%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-d608449>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-d608449 {
      //  --container-widget-width: 50%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 50%);
      //  max-width: 50%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-74cd024>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-74cd024 {
      //  --container-widget-width: 50%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 50%);
      //  max-width: 50%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-7a5983b>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-7a5983b {
      //  --container-widget-width: 50%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 50%);
      //  max-width: 50%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-9aa8315>.elementor-widget-container {
      //  perspective: 1200px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-9aa8315 {
      //  --container-widget-width: 50%;
      //  --container-widget-flex-grow: 0;
      //  width: var(--container-widget-width, 50%);
      //  max-width: 50%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-179fe94c {
      //  --padding-top: 90px;
      //  --padding-bottom: 80px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-3d15eeef {
      //  --margin-top: 0px;
      //  --margin-bottom: 30px;
      //  --margin-left: 0px;
      //  --margin-right: 0px;
      //  --padding-top: 0px;
      //  --padding-bottom: 0px;
      //  --padding-left: 0px;
      //  --padding-right: 0px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-1570d8c {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-21ad33f5 {
      //  --width: 5%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-1366076c {
      //  --width: 5%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-752a8f4a {
      //  --width: 5%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-26ce90b4 {
      //  --width: 5%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-2fdff4c1 {
      //  --width: 5%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-662944d6 {
      //  --width: 5%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-422c2f77 {
      //  --width: 5%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-35c3f41 {
      //  --padding-top: 40px;
      //  --padding-bottom: 90px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-8d5e31f {
      //  text-align: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-4905f912 {
      //  width: 100%;
      //  max-width: 100%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-373b68ad .lqd-carousel-nav-prev {
      //  inset-inline-start: 0px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-373b68ad .lqd-carousel-nav-next {
      //  inset-inline-end: 0%;
      //}

      //.elementor-1430 .elementor-element.elementor-element-69783964 {
      //  --flex-direction: row;
      //  --container-widget-width: initial;
      //  --container-widget-height: 100%;
      //  --container-widget-flex-grow: 1;
      //  --container-widget-align-self: stretch;
      //  --flex-wrap: wrap;
      //  --padding-top: 40px;
      //  --padding-bottom: 40px;
      //  --padding-left: 20px;
      //  --padding-right: 20px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-5a2647f0 {
      //  text-align: left;
      //}

      //.elementor-1430 .elementor-element.elementor-element-e48f175 .lqd-btn {
      //  justify-content: center;
      //}

      //.elementor-1430 .elementor-element.elementor-element-59f19009 {
      //  --min-height: 440px;
      //}

      //.elementor-1430 .elementor-element.elementor-element-41c90576 .lqd-text-el {
      //  font-size: 12vw;
      //} */
  }
/* </style>
<style> */
  /* *,
  ::after,
  ::before {
    box-sizing: border-box;
    border-width: var(--lqd-global-border-w, 0);
    border-style: var(--lqd-global-border-style, solid);
    border-color: var(--lqd-global-border-color, currentColor);
  } */

  .elementor-widget-container h2 {
    font-family: var(--lqd-h2-font-family, var(--lqd-heading-font-family));
    /* //font-size: var(--lqd-h2-font-size, var(--lqd-heading-font-size));
  //line-height: var(--lqd-h2-line-height, var(--lqd-heading-line-height));
  //font-weight: var(--lqd-h2-font-weight, var(--lqd-heading-font-weight)); */
    font-style: var(--lqd-h2-font-style, var(--lqd-heading-font-style));
    text-transform: var(--lqd-h2-text-transform, var(--lqd-heading-text-transform));
    /* //letter-spacing: var(--lqd-h2-letter-spacing, var(--lqd-heading-letter-spacing)); */
    color: var(--lqd-h2-color, var(--lqd-heading-color));
    /* margin-top: 1.05em;
    margin-bottom: 1.05em; */
  }

  .elementor-widget {
    --lqd-trigger-bars-count: 2;
    --lqd-trigger-bars-spacing: 5px;
    --lqd-trigger-bar-width: 21px;
    --lqd-trigger-bar-height: 2px;
  }

  :root {
    --lqd-global-border-w: 0;
    --lqd-global-border-style: solid;
    --lqd-global-border-color: currentColor;
    --lqd-header-width: 100%;
    --lqd-header-max-width: 100%;
    --lqd-color-primary: #7458E8;
    --lqd-body-bg: #fff;
    --lqd-body-font-family: "Rubik", sans-serif;
    --lqd-body-font-size: 18px;
    --lqd-body-line-height: 1.5em;
    --lqd-link-color: inherit;
    --lqd-heading-font-family: "Manrope", sans-serif;
    --lqd-heading-line-height: 1.5em;
    --lqd-heading-font-weight: 600;
    --lqd-heading-color: #000;
    --lqd-h1-font-size: 3.5em;
    --lqd-h1-line-height: 1.07em;
    --lqd-h2-font-size: 2.9em;
    --lqd-h2-line-height: 1.05em;
    --lqd-h3-font-size: 2em;
    --lqd-h4-font-size: 1.5em;
    --lqd-h5-font-size: 1.15em;
    --lqd-h6-font-size: 0.78em;
    --lqd-transition-duration: 0.65s;
    --lqd-transition-timing-function: cubic-bezier(0.23, 1, 0.320, 1);
  }

  /* html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
  } */


  .lqd-text-tag-div,
  .lqd-text-tag-p,
  .lqd-text-tag-span {
    color: var(--lqd-text-color, var(--lqd-container-text-color, var(--lqd-body-text-color, var(--e-global-color-text, inherit))));
  }

  /* body {
    margin: 0;
    line-height: inherit;
    overflow-x: hidden;
    background: var(--lqd-body-bg);
    transition: background .3s;
    font-family: var(--lqd-body-font-family);
    font-size: var(--lqd-body-font-size);
    font-weight: var(--lqd-body-font-weight);
    font-style: var(--lqd-body-font-style);
    line-height: var(--lqd-body-line-height);
    color: var(--lqd-body-text-color, var(--e-global-color-text));
  } */
/* </style> */

.e-con.e-parent:nth-of-type(n+4):not(.e-lazyloaded):not(.e-no-lazyload),
  .e-con.e-parent:nth-of-type(n+4):not(.e-lazyloaded):not(.e-no-lazyload) * {
    background-image: none !important;
  }

  @media screen and (max-height: 1024px) {

    .e-con.e-parent:nth-of-type(n+3):not(.e-lazyloaded):not(.e-no-lazyload),
    .e-con.e-parent:nth-of-type(n+3):not(.e-lazyloaded):not(.e-no-lazyload) * {
      background-image: none !important;
    }
  }

  @media screen and (max-height: 640px) {

    .e-con.e-parent:nth-of-type(n+2):not(.e-lazyloaded):not(.e-no-lazyload),
    .e-con.e-parent:nth-of-type(n+2):not(.e-lazyloaded):not(.e-no-lazyload) * {
      background-image: none !important;
    }
  }

  .landing-screen{
    display: null;
  }
  
  @media screen and (max-width: 1279px) {
    .landing-screen{
          display: none;
      }
  }
/*---Company Banner End---*/

/*---Confirm Delete Popup Start---*/
.loader-center{
  align-content: center;
  text-align: center;
}
/*---Confirm Delete Popup End---*/

/*---Basic Deployment Form Start---*/
.description {
  color: var(--cds-global-typography-color-300, #454545);
  font-size: 0.9em;
  display: block;
}

.subsection {
  border: 2px solid var(--cds-alias-object-border-color, #f1f1f1);
  border-radius: 5px;
  padding: 10px;
}

.block:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.centered {
  display: inline-block;
}

.basic-deployment-form-param {
  padding: 0.6rem 0 0.6rem 0;
}

.deployment-form-label {
  font-weight: 600;
  margin-bottom: 0.2rem;
}
.deployment-form-label-text-param {
  display: block;
}

.slider-block {
  display: flex;
}
.slider-block .slider-input-and-unit {
  margin-left: 0.6rem;
}
.slider-block .slider-content {
  width: 30vw;
  margin-left: 10px;
  margin-top: 15px;
}
.slider-block .slider-input {
  width: 50%;
}
/*---Basic Deployment Form End---*/

/*---DNS Popup Start---*/
.codeDivdns {
  background-color: #272c34;
  color: #fff!important;
  margin: 24px auto;
  overflow: auto;
  border-radius: 4px;
  padding: 20px 16px 16px;
}
/*---DNS Popup End---*/

/*---Email Input Start---*/
.react-multi-email {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  /* padding: 0.8em 0.6em; */
  padding: 12px 14px;
  background: #fff;
  border: 1px solid rgba(145, 158, 171, 0.2);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 13px;
  position: relative;
}

.react-multi-email > input {
  width: 100%!important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
  font-size: 15px;
  color: rgba(28, 37, 46, 1);
}

.react-multi-email > input::placeholder {
  color: rgba(145, 158, 171, 1); 
  opacity: 1; 
}
/*---Email Input End---*/

/*---Env Insights Start---*/
.lineChartContainer{
  padding : 1rem 1rem 0.9rem 0.9rem
}

.lineChartTitle{
  color:grey;
  font-size: 0.9rem;
  font-weight:500
}

.graphDropdown{
  min-width: 120px !important;
}

.graphDropdownContainer{
  display: flex;
  justify-content: flex-end;
}
/*---Env Insights End---*/

/*---Env Logs Start---*/
.podlogs{
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.arrowgodown{
position: absolute;
top:10px;
right:10px;
}
.arrowgodownbtn{
  background-color: white!important;
}
/*---Env Logs End---*/

/*---Env Card Start---*/
.addonsavatar img{
    width:40px;
    margin-right:10px;
}
.envstatus{
    display: flex;
    margin-bottom: 20px;
    margin-top:10px;
}
.envstatussvg{
    margin-right:5px;
width:20px!important;
}

.lastbuilddiv{
align-content: center;
display: flex;
}

.branchdiv{
    align-content: center;
    display: flex; 
}
.cardtopstatus{
    border-top:5px solid #f8f8f8;
}
.bottomcard{
    background: #f8f8f8!important;
}
.cardtitle{
font-weight: 600;
}
.statusTxt{
    font-size: 14px;
    vertical-align: middle;
}
.statusTxtenv{
  font-size: 12px;
  vertical-align: middle;
  font-weight: 700;
  line-height: 20px;
}
.statusIcon{
    font-size: 1rem !important;
    vertical-align: middle;
    margin-right: 3px;
}
/*---Env Card End---*/

/*---HPA Start---*/
.advancediv{
  padding-top:40px;
  padding-bottom: 40px;
}

.hpaIconCircle{
overflow: hidden;
position: relative;
align-items: center;
flex-shrink: 0;
line-height: 1;
border-radius: 50%;
justify-content: center;   
background: #0057fa;
color:white;
margin-left:20px;
}
.scaleTitle{
display: inline;
}

.replica-slider{
    width: 100%;
    height: 50px;
}
.textRight{
    text-align:right;
}

.scheduling{
    color: #2979ff;
    font-size: 20px;
    margin-left: -12px;
    margin-top: 5px;
}
/*---HPA End---*/

/*---Loader Start---*/
.lds-ellipsis {
display: inline-block;
position: relative;
width: 80px;
height: 70px;
}
.lds-ellipsis div {
position: absolute;
top: 45px;
width: 13px;
height: 13px;
border-radius: 50%;
background: #e8e8e8;
animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(2) {
left: 8px;
animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
left: 8px;
animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
left: 32px;
animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(5) {
left: 56px;
animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
0% {
transform: scale(0);
}
100% {
transform: scale(1);
}
}
@keyframes lds-ellipsis3 {
0% {
transform: scale(1);
}
100% {
transform: scale(0);
}
}
@keyframes lds-ellipsis2 {
0% {
transform: translate(0, 0);
}
100% {
transform: translate(24px, 0);
}
}

.loader_icon{
width:50px;
display: block;
margin-left: auto;
margin-right: auto;
}
/*---Loader End---*/

/*---Add Operator Popup Start---*/
.add-operator-popup-container .operator-list {
  padding: 0 3px;
  height: 700px;
  overflow-y: auto;
}

.add-operator-popup-container .ReactVirtualized__Grid__innerScrollContainer{
  padding-bottom: 10px;
}

.add-operator-popup-container .no-search-result{
  margin-top: 50px;
  text-align: center;
}

.add-operator-popup-container .MuiDialogTitle-root{
    padding: 10px !important;
}

.add-operator-popup-container .dialogue-title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-operator-popup-container .dialogue-title-container .dialogueTitle{
    font-size: 16px;
    font-weight: 500;
}

.add-operator-popup-container .dailogue-content {
  padding: 10px !important;
}

.add-operator-popup-container .dailogue-content .dialogue-content-title {
  padding-left: 10px;
}

.add-operator-popup-container .action-container{
  margin-bottom: 10px;
}

.add-operator-popup-container .skeleton-container{
  margin-top: 15px;
}

.add-operator-popup-container .skeleton-container .skeleton-item{
  padding: 15px;
  margin-bottom: 10px;
}

@media (min-width: 700px){
  .add-operator-popup-container > .MuiDialog-container > .MuiDialog-paper{
    min-width: 600px;
  }
}

@media (max-height: 700px) {
  .add-operator-popup-container .operator-list {
    height: 400px;
  }
}
/*---Add Operator Popup End---*/

/*---Installed Operator Item---*/
.installed-operator-item-container {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.installed-operator-item-container .img-icon {
  margin-top: 10%;
  height: 70px;
}

.installed-operator-item-container .operator-title {
  margin-top: auto;
  text-transform: none;
  width: 100%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
}

.installed-operator-item-container .operator-status {
  width: 100%;
  height: 5px;
}

.installed-operator-item-container .operator-status.installed-operator-status {
  background-color: green;
}

.installed-operator-item-container .operator-status.failed-operator-status {
  background-color: red;
}

.installed-operator-item-container .operator-status.other-operator-status {
  background-color: orange;
}
/*---Installed Operator Item End---*/

/*---Operator Collapsible Card Start---*/
.operator-collapsible-card-container {
  margin: 0 3px;
}

.operator-collapsible-card-container .collapsible-card-header {
  padding: 10px !important;
  cursor: pointer;
  height: 75px;
}

.operator-collapsible-card-container .collapsible-card-header .operator-icon {
  max-width: 50px;
  max-height: 50px;
}

.collapsible-card-header .operator-expand {
  transform: rotate(0deg);
  transition: all 150ms;
}

.collapsible-card-header .operator-expand.expanded {
  transform: rotate(180deg);
}

.operator-collapsible-card-container
  .collapsible-card-header
  .operator-version {
  font-size: 12px;
  color: #a6a6a6;
  margin: 0 !important;
}

.operator-collapsible-card-container .operator-collapse.MuiCollapse-entered {
  height: 255px !important;
}

.operator-collapsible-card-container
  .operator-collapse
  .operator-collapse-body {
  padding: 15px;
}

.operator-collapsible-card-container
  .operator-collapse
  .operator-collapse-body
  .operator-info-container {
  margin: 10px 0;
  font-size: 14px;
  color: #64655f;
}

.operator-collapsible-card-container
  .operator-info-container
  .operator-info-label {
  font-weight: 600;
}
/*---Operator Collapsible Card---*/

/*---Operator Form Start---*/
.operator-form-container {
  padding: 0 15px !important;
  font-size: 15px;
}

.operator-form-body-container {
  padding: 0 15px 15px !important;
  font-size: 15px;
}

.operator-form-container .operator-icon {
  max-width: 50px;
  max-height: 50px;
}

.operator-form-container .operator-title {
  font-weight: 600;
  color: #000000;
}

.operator-form-body-container .operator-version {
  font-weight: 600;
}

.operator-form-body-container .clr-form-control .clr-control-label {
  font-weight: 600;
}

.operator-form-body-container .clr-form-control .clr-control-helper {
  font-size: 14px;
  color: #8f91a2;
}

.operator-form-body-container .MuiSvgIcon-root {
  font-size: 1.15rem;
}

.operator-form-body-container .MuiFormControlLabel-label {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
/*---Operator Form End---*/

/*---Operator Info Popup Start---*/
@media (min-width: 800px){
  .operator-info-popup-container > .MuiDialog-container > .MuiDialog-paper{
    min-width: 700px;
  }
}
/*---Operator Info Popup End---*/

/*---Container Logs Start---*/
.logs-container {
  position: relative;
}

.logs-container .launch-external-logs {
  position: absolute;
  top: 5px;
  right: 20px;
  padding: 5px;
  background-color: #ffffff;
}
/*---Container Logs End---*/

/*---Project Card Details Start---*/
.projectListsContainer{
	display: flex;
	padding: 24px;
	align-items: center;
	justify-content: space-between;
  /* min-height: 100px; */
}
.projectImageDisabled{
	filter: grayscale(100%);
   -webkit-filter: grayscale(100%);
   opacity: 0.5;
   
}

.projectSmallTitle{
	color : #0057fa;
	font-size: 1rem;
}

.projectTitle{
	color: #0057fa;
	margin-left: 1rem;
	font-weight : 500;
}
.envTitle{
	color: #0057fa;
	font-weight : 500;
}

.apps{
	display: block;
	margin-left: 1rem;
	color: #43425D;
	opacity: 0.9;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.projectTitleShorter {
	width: 30px;
	height: 30px;
    border: 1px solid #0057fa;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0; 
  }
  
  .projectTitleShorter::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .projectImg{
	height:40px;
	/* width: 100%; */
	object-fit: contain;
  }

.svgiconimg{
	height: 18px;
    margin-right: 5px;
}
/* .topinfoGrid{
font-size: 14px;
} */

.topgridalign{
  align-items: center;
  display: flex;
}
.envavatardiv{
  margin-top: 10px;
	display: flex;
  align-items: center;
  justify-content: space-between;
}
.envavatar{
	margin-right: 6px;
}
.addenvavatar{
	margin-right: 8px;
}
.carddivider{
	margin-top:10px!important;
	margin-bottom:10px!important;
}
/*---Project Card Details End---*/

/*---Response Paper Start---*/
.card-Selected{
  width:100%;
}

.resourcesdiv img{
width: 20px;
display: block;
margin: auto;
margin-bottom:5px;
}
.storageswitch{
  margin-top: 20px;
}
.newClass{
    margin:5px;
}
/*---Response Paper End---*/

/*---Status Indicator Start---*/
.successStep{
  color:#198038;
}

.runningStep{
color:#f5c60b;
}

.pendingStep{
color: rgb(196, 191, 191);
}

.failedStep {
color: #f30f0f;
}

.lds1-ring {
display: inline-block;
position: absolute;
width: 24px;
height: 24px;
margin-left: -24px;
}

.lds1-ring div {
box-sizing: border-box;
display: block;
position: absolute;
width: 12px;
height: 12px;
margin: 6px;
border: 1.5px solid white;
border-radius: 50%;
animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
border-color: white transparent transparent transparent;
}

.lds1-ring div:nth-child(1) {
animation-delay: -0.45s;
}
.lds1-ring div:nth-child(2) {
animation-delay: -0.3s;
}
.lds1-ring div:nth-child(3) {
animation-delay: -0.15s;
}
@keyframes lds1-ring {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}

.lds1-hour {
display: inline-block;
position: absolute;
width: 24px;
height: 24px;
margin-left: -20px;
top:4px;
}

.pendingStepIcon{
  color: white;
  font-size: 15px!important;
}

.status-circle {
  position: relative;
}  
/*---Status Indicator End---*/

/*---Subscription Card Start---*/
.SubscriptionCard-container{
	min-height: 214px !important; 
}

.SubscriptionCard-header{
	max-width: 100% !important;
	color: white;
	background-color: #0057fa;
	font-weight: bold;
	border-bottom: 1px solid #B7B9CB;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 50px !important;
}

.SubscriptionCard-header-disabled{
	max-width: 100% !important;
	color: white;
	background-color: #89acf4;
	font-weight: bold;
	border-bottom: 1px solid #B7B9CB;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 50px !important;
}

.SubscriptionCard-pricing{
	max-width: 100% !important;
	color: white;
	background-color: #0057fa;
	border-bottom: 1px solid #B7B9CB;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 60px !important;
}

.SubscriptionCard-pricing-disabled{
	max-width: 100% !important;
	color: white;
	background-color: #f9f6f6;
	border-bottom: 1px solid #B7B9CB;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 60px !important;
}

.SubscriptionCard-content {
	max-width: 100% !important;
	color: white;
	background-color: #0057fa;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 150px !important;
}

.SubscriptionCard-content-disabled {
	max-width: 100% !important;
	color: white;
	background-color: #f9f6f6;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 150px !important;
}


.sc-contentText{
	color : #ffffff;
	margin-bottom : 12px;
	margin-top: 0px;
}

.sc-contentText-disabled{
	color : #43425D;
	margin-bottom : 12px;
	margin-top: 0px;
}


.sc-coreText{
	color : #ffffff;
	margin-bottom : 12px;
	margin-top: 6px;
}

.sc-coreText-disabled{
	color : #43425D;
	margin-bottom : 12px;
	margin-top: 6px;
}


.price{
	font-size: 20px;
}

.price-disabled {
	color:#a6a3ae;
	font-size: 20px;
}

.perMonth{
	font-size: 16px;
	color: #ffffff;
	position: relative;
	left: 4%;
}

.perMonth-disabled{
	font-size: 16px;
	color: #43425D;
	position: relative;
	left: 4%;
}

.disabled-card :hover{
	cursor: not-allowed;
}

.disabled-card {
	background-color: #d8d9db !important;
}
/*---Subscription Card End---*/

/*---Subscription List Start---*/
.fullWidth{
  width: 60% !important;
  margin : 0 auto !important;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .fullWidth {
      width: 100% !important;
  }
} 
/*---Subscription List End---*/

/*---TextFiled Start---*/
.form-control{
  display: block;
  min-width: 35%;
  height: calc(1.25em + .5rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding : .05rem 0.5rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-label{
  display: inline-block;
  margin-bottom: .5rem;
  font-size: 16px;
  letter-spacing: -0.05px;
  line-height: 21px; 
  color: #43425d; 
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
/*---TextField End---*/
/* input[type="password"]
{
  -webkit-text-security: square !important;
} */

/*---Delete Popup Start---*/
.deletepopup .MuiPaper-rounded{
  border-radius: 16px !important;
}

.deletepopup .MuiDialogActions-root{
  padding: 16px 24px 16px 24px !important
}
/*---Delete Popup End---*/

/*---Confirmation Popup Start---*/
/* .confirmationpopup .MuiDialogContent-dividers{
  border: 0px !important;
} */

.confirmationpopup .MuiPaper-rounded{
  border-radius: 16px !important;
}

.confirmationpopup .MuiDialogActions-root{
  padding: 16px 24px 16px 24px !important
}
/*---Confirmation Popup End---*/

/*---Create Enironment Start---*/
.w-100 .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.installationButton{
  min-height: 76px;
}

.m-t-24 .MuiCardContent-root {
  /* padding: 0 24px 24px !important;  */
  padding: 24px !important;
}
/*---Create Enironment end---*/

/*---Network tab start---*/
.network .MuiCardHeader-action{
  margin-top: 2px;
}

.storage-cardaction .MuiCardActions-root{
  padding: 16px !important;
}

.emptyKeyValueRow{
  height: 18px !important;
}

.tableHeadcontent{
  width: 564px;
}

.tableHeadAction{
  border-left: 1px solid white;
  width: 140px;
}
/*---Network tab end---*/

/* Custom file upload START*/
.custom-file-upload{
  border: 1px dotted #919EAB14;
  border-radius: 12px;
  background-color: #919EAB14;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  color: #919EAB !important;
  margin-top: 10px;
}

.toast-light {
  background-color: transparent !important; 
  box-shadow: none !important;
}
/* Custom file upload END*/

/* --- Subsricption Form Start---*/
.subscription-form .MuiFormControl-marginNormal{
  margin-bottom: 16px !important;
}
.subscription-form .MuiFormControlLabel-root{
  margin-top: 16px;
  margin-left: 0px !important;
}
/* --- Subsricption Form End---*/

/* --- Resource Form Start---*/
.resource-form .MuiFormControlLabel-root{
  margin-left: 0px !important;
}

.newrun-scan .makeStyles-backBtn-86 {
  margin-bottom: 0px !important;
}

.circle-background {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white; 
  overflow: hidden; 
}

.circle-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
      radial-gradient(circle at bottom left, rgba(255, 86, 51, 0.1) 80px, transparent 80px),
      radial-gradient(circle at top right, rgba(0, 184, 217, 0.1) 80px, transparent 80px);
  filter: blur(30px); 
  pointer-events: none; 
}

.centeralign {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.whitecontainer{
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  width: 99%;
}

.clusterwhitecontainer{
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  /* width: 99%; */
  position: relative;
}

.alertwhitecontainer{
  background-color: white;
  border-radius: 16px;
  padding: 16px;
}
/* --- Resource Form End---*/

/*---ProjectInfo Start---*/
.projectimgWrapper{
  width: 120px;
  height: 120px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
}
.projectimgWrapperUploaded{
  width: 80px;
  height: 80px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
}
.projectimgContainer{
  position: relative;
}
.projectcirclebtn{
  background-color: transparent;
  position:absolute;
  right:-5px;
  height:30px;
  width:30px;
  border-radius:50%;
  top:-10px;
}
/*---ProjectInfo End---*/

/*---User Image Upload Start---*/
.userimage-upload-container{
  border: 1px dashed #919EAB33;
  border-radius: 12px;
  background-color: #919EAB14;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  color: #919EAB !important;
  margin-top: 10px;
}
/*---User Image Upload End---*/

.chartCatalog .MuiCardContent-root {
  padding: 0px !important;
}
.chartCatalog .MuiGrid-spacing-xs-2{
  margin: 0px !important;
  width: 100% !important;
}

tspan{
  color: "#919EAB" !important;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
}

/*--React Corn Start--*/
.react-js-cron > div, .react-js-cron-field{
  gap: 10px !important;
}
div.react-js-cron-select{
  min-width: 160px !important;
  min-height: 40px !important;
  border-radius: 8px !important;
  align-items: center;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 8px !important;
  min-height:  40px !important;
}
.react-js-cron{
  gap: 16px !important;
}
div.react-js-cron-select.react-js-cron-custom-select.ant-select div.ant-select-selector{
  border-radius: 8px !important;
  min-height:  40px !important;
}
.ant-btn-dangerous.ant-btn-primary{
  background-color: #1C252E;
  border-color: #1C252E;
  border-radius: 8px !important;
  min-height: 40px !important;
}
.ant-select-single .ant-select-selector{
  align-items: center;
}

.sliceContianer .MuiFormControlLabel-root{
  margin-left: 0px !important;
}
/*---React Corn End---*/

/*---Alert Start---*/
.alertNotification .MuiCardActions-root{
  padding-left: 16px !important;
}

.link-card .MuiCardContent-root:last-child{
  padding-bottom: 6px !important;
}

.link-card .MuiCardContent-root{
  padding: 6px;
}

.link-card-container .MuiCardContent-root:last-child{
  padding-bottom: 16px !important;
}

.link-card-container .MuiCardContent-root:last-child{
  padding: 16px;
}
/*---Alert End---*/

/*---CI/CD Script Start---*/
.scripts .MuiCardHeader-action{
  margin-top: 2px !important;
  margin-right: 0px !important;
}

.cronjobList .MuiCardHeader-action{
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.cronjobList .label-handler-container {
  justify-content: left;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}

.cronjobList .MuiFormControlLabel-labelPlacementStart{
  margin-left: 8px !important;
}

.cronjobList .MuiSwitch-root{
  margin-left: 9px !important;
}

.cornjobInfo .MuiCardContent-root{
  padding-top: 0px !important;
}

.ciSettings .MuiCardHeader-action{
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.cisettingswitch .MuiFormControlLabel-root{
  margin-left: 0px !important;
}

.ciSettingsemail .MuiFormControlLabel-labelPlacementStart{
  margin-left: 0px !important;
}

.ciSettingsemail .MuiSwitch-root{
  margin-left: 24px;
}
/*---CI/CD Script ends---*/

/*---Application Card Start---*/
.appcardcontent .MuiCardContent-root:last-child{
  padding-bottom: 20px !important;
  min-height: 200px;
}

.appcardcontent .MuiCardContent-root{
  padding: 20px;
}

.accessTokenCaption {
  color: #637381;
  font-weight: 400;
  font-size: 12px !important;
  letter-spacing: 0.33px !important;
  line-height: 18px !important;
}

.appcardlabelText {
  font-weight: 500;
  font-size: 10px !important;
  line-height: 16px !important;
}

.w-md-editor-toolbar {
  border-radius: 16px 16px 0 0 !important;
}
/*---Application Card---*/

/*---StartupJob Start---*/
.startupActionContainer {
  display: flex; 
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}
.startupContainer .MuiCardActions-root{
  padding: 16px !important;
}
/*---StartupJon End---*/

/*---Variables Start---*/
.uservariables .MuiCardHeader-action {
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.uservariables .MuiGrid-spacing-xs-2{
  margin: 0px !important;
}
/*---Variables Start---*/

/*---Breadcrumbs Start---*/
.breadcrumb ul {
  list-style: none;
  display: inline-table;
  padding:0;
  margin:0;
}
.breadcrumb ul li {
  display: inline;
}
.breadcrumb ul li a {
  display: flex;
  float: left;
  height:20px;
  text-align: left;
  padding: 5px 0px 5px 5px;
  position: relative;
  text-decoration: none;
  font-size: 16px;
  align-items: center;
  color: #919EAB;
  font-weight: 400;
  gap: 5px;
}
.breadcrumb ul li:last-child a {
  font-size: 16px;
  color: #1C252E;
  font-weight: 600;
}
.breadcrumb ul li a:hover {
  color: #1C252E;
  /* font-weight: 600; */
}

/*---Toaster Start---*/
.Toastify__toast{
  box-shadow: none !important;
  max-height: 64px;
}

.Toastify__toast--default{
  background-color: transparent !important;
}
/*---Toaster End---*/

/*---Scroll bar Start---*/
::-webkit-scrollbar {
  width: 6px; 
}
::-webkit-scrollbar-track {
  background-color: transparent; 
  border-radius: 10px; 
}
::-webkit-scrollbar-thumb {
  background-color: #919EAB; 
  border-radius: 10px; 
}
/*---Scroll bar End---*/

.createOrg .MuiCardHeader-action{
  margin-top: 0px !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
      min-width: auto !important;
  }
}

.payment-dialog .MuiDialog-paperWidthSm {
  max-width: 712px !important;
}

.billing-history{
  height: auto;
}

@media (min-width: 1260px) {
  .billing-history{
    height: 100%;
  }
}

.billing-history .MuiCard-root{
  height: 100%;
}

.billing-history .MuiCardContent-root:last-child{
  padding-bottom: 0px !important;
}
